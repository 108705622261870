import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { DataTable } from 'react-native-paper';

const PaginationComponent = ({ numberOfItemsPerPage, setNumberOfItemsPerPage, page, setPage, fetchResult, loading = false, setLoading }) => {
  const perPageValues = [10, 25, 50];
  const ALL_ROWS_INDEX = perPageValues.length;

  const handlePerPageChange = (itemValue, itemIndex) => {  
    if (itemIndex === ALL_ROWS_INDEX) {
      setLoading(true);
    } 
    setNumberOfItemsPerPage(itemValue);
  };

  return (
    <View style={styles.pagination}>
      <View style={styles.pagination__dropdown}>
        <Text style={styles.pagination__dropdown__header}>Rows per page</Text>

        <Picker
          style={styles.picker}
          selectedValue={numberOfItemsPerPage}
          onValueChange={(itemValue, itemIndex) => handlePerPageChange(itemValue, itemIndex)}>

          {perPageValues.map(value => (
            <Picker.Item key={value} label={value.toString()} value={value} />
          ))}
          
          <Picker.Item label="All" value= {fetchResult?.data?.pagination.totalCount} />
        </Picker>
      </View>
      <DataTable.Pagination
        page={page}
        numberOfPages={fetchResult.data?.pagination.totalPages}
        onPageChange={(page) => setPage(page)}
        label={`${fetchResult?.data?.pagination.currentStartIndex}-${fetchResult?.data?.pagination.currentEndIndex} of ${fetchResult?.data?.pagination.totalCount}`}
        showFastPaginationControls
        numberOfItemsPerPage={numberOfItemsPerPage}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  pagination: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  pagination__dropdown: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  pagination__dropdown__header: {
    paddingRight: 5,
    fontFamily: 'OpenSans-Light',
  },
  picker: {
    borderWidth: 1,
    borderRadius: 10,
    overflow: 'hidden',
    width: 40, 
    height: 20, 
  },
});

export default PaginationComponent;
