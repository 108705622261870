import { SimpleLineIcons } from "@expo/vector-icons";
import GlobalStyle from "../../../../constants/GlobalStyle";
import { View, Text, StyleSheet, TextInput, TouchableOpacity } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { useSelector } from 'react-redux';
import { RootState } from '@store/index';
import DateTimePickerWeb from "../navigation/controls/DateTimePicker.web";

export {
  SearchFilters,
  CompanyIdFilter,
  LocationFilter,
  JobTypeFilter,
  JobNumberFilter,
  DateFilter,
  DateRangeFilter,
  EngineersFilter,
  SerialFilter,
  DescriptionFilter,
  StatusFilter,
  SubLocationFilter,
  CategoryFilter,
  ReferenceFilter,
  SearchFilterRow,
  SearchFilterButtons,
  ArchivedStatusFilter,
  ScrappedStatusFilter,
  AssetFilter
}

function SearchFilters({ children }) {
  return (
    <View style={GlobalStyle.filters}>
      <View style={GlobalStyle.filters__header}>
        <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
      </View>
      <View style={GlobalStyle.filter__columns}>
        {children}
      </View>
    </View>
  )
}

function SearchFilterButtons({ onSubmit, onClearFilters }) {
  return (
    <View style={[GlobalStyle.column__flex, styles.filter__buttons__container]}>
      <View style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
        <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={onSubmit}>
          <SimpleLineIcons name="magnifier" size={16} color="white" />
        </TouchableOpacity>

        <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={onClearFilters}>
          <SimpleLineIcons name="ban" size={16} color="black" />
        </TouchableOpacity>
      </View>
    </View>
  )
}

function SearchFilterRow({ children }) {
  return (
    <View style={styles.filter__row}>
      {children}
    </View>
  )
}

function CompanyIdFilter({ value, onValueChange, companies }) {
  const user = useSelector((state: RootState) => state.user.user);

  return (
    <FilterOption title="Company">
      <Picker
        style={[GlobalStyle.column, GlobalStyle.column__input]}
        selectedValue={value ?? ""}
        enabled={user.role != 'User'}
        onValueChange={onValueChange}>

        <Picker.Item label="All Companies" value="" />

        {companies != null && companies.map((value) => {
          return (
            <Picker.Item key={value.id} label={value.name} value={value.id} />
          )
        })}
      </Picker>
    </FilterOption>
  )
}

function LocationFilter({ companyId, value, onValueChange, locations }) {
  return (
    <FilterOption title="Location">
      <Picker
        style={[GlobalStyle.column, GlobalStyle.column__input]}
        selectedValue={value}
        onValueChange={onValueChange}>

        <Picker.Item label="All Locations" value="" />

        {locations != null && locations.filter(l => companyId == "" || l.companyId == companyId).map((value, index) => {
          return (
            <Picker.Item key={value.id} label={value.name} value={value.id} />
          )
        })}
      </Picker>
    </FilterOption>
  )
}

function SubLocationFilter({ value, onValueChange, companyId, subLocations, locationId }) {
  return (
    <FilterOption title="Sub Location">
      <Picker
        style={[GlobalStyle.column, GlobalStyle.column__input]}
        selectedValue={value}
        onValueChange={onValueChange}>

        <Picker.Item label="All Sub Locations" value="" />

        {subLocations != null && subLocations.filter(sl => locationId == "" && companyId == "" || sl.locationId == locationId).map((value, index) => {
          return (
            <Picker.Item key={value.id} label={value.name} value={value.id} />
          )
        })}
      </Picker>
    </FilterOption>
  )
}

function JobTypeFilter({ value, onValueChange }) {
  return (
    <FilterOption title="Job Type">
      <Picker
        style={[GlobalStyle.column, GlobalStyle.column__input]}
        selectedValue={value}
        onValueChange={onValueChange}>
        <Picker.Item label="All" value="-1" />
        <Picker.Item label="ROTE" value="0" />
        <Picker.Item label="Servicing" value="1" />
        <Picker.Item label="Breakdown" value="2" />
      </Picker>
    </FilterOption>
  )
}

function DateFilter({ title, value, onValueChange }) {
  return (
    <FilterOption title={title}>
      <DateTimePickerWeb
        style={[GlobalStyle.column, GlobalStyle.column__input]}
        onChange={onValueChange}
        value={value}>
      </DateTimePickerWeb>
    </FilterOption>
  )
}

export type DateRangeOptions = {
  label: string,
  value: string
}

function DateRangeFilter({ title, value, onValueChange, options = [] }) {
  return (
    <FilterOption title={title}>
      <Picker
        style={[GlobalStyle.column, GlobalStyle.column__input]}
        selectedValue={value}
        onValueChange={onValueChange}>
        {options?.map((opt, i) => <Picker.Item key={i} label={opt.label} value={opt.value} />)}
      </Picker>
    </FilterOption>
  )
}

function EngineersFilter({ value, onValueChange, engineers }) {
  return (
    <FilterOption title="Engineers">
      <Picker
        style={[GlobalStyle.column, GlobalStyle.column__input]}
        selectedValue={value}
        onValueChange={onValueChange}>

        <Picker.Item label="All" value="" />

        {engineers != null && engineers.map((value) => {
          return (
            <Picker.Item key={value.id} label={value.name} value={value.id} />
          )
        })}
      </Picker>
    </FilterOption>
  )
}

function DescriptionFilter({ value, onValueChange }) {
  return (
    <TextInputFilter title="Description" value={value} onValueChange={onValueChange} />
  )
}

function SerialFilter({ value, onValueChange }) {
  return (
    <TextInputFilter title="Serial #" value={value} onValueChange={onValueChange} />
  )
}

function AssetFilter({ value, onValueChange }) {
  return (
    <TextInputFilter title="Asset #" value={value} onValueChange={onValueChange} />
  )
}

function JobNumberFilter({ value, onValueChange }) {
  return (
    <TextInputFilter title="Job Number" value={value} onValueChange={onValueChange} />
  )
}

function TextInputFilter({ title, value, onValueChange }) {
  return (
    <FilterOption title={title}>
      <TextInput
        style={[GlobalStyle.column, GlobalStyle.column__input]}
        onChangeText={onValueChange}
        value={value} />
    </FilterOption>
  )
}

function ReferenceFilter({ value, onValueChange }) {
  return (
    <FilterOption title="Your Reference">
      <TextInput
        style={[GlobalStyle.column, GlobalStyle.column__input]}
        onChangeText={onValueChange}
        value={value} />
    </FilterOption>
  )
}

function StatusFilter({ value, onValueChange }) {
  return (
    <FilterOption title="Status">
      <Picker
        style={[GlobalStyle.column, GlobalStyle.column__input]}
        selectedValue={value}
        onValueChange={onValueChange}>

        <Picker.Item label="All" value="-1" />
        <Picker.Item label="Safe For Use" value="0" />
        <Picker.Item label="Failed" value="1" />
        <Picker.Item label="Not Presented" value="2" />
      </Picker>
    </FilterOption>
  )
}

function ArchivedStatusFilter({ value, onValueChange }) {
  return (
    <FilterOption title="Archived Status">
      <Picker
        style={[GlobalStyle.column, GlobalStyle.column__input]}
        selectedValue={value}
        onValueChange={onValueChange}>

        <Picker.Item label="All" value="-1" />
        <Picker.Item label="Active" value="0" />
        <Picker.Item label="Archived" value="1" />
      </Picker>
    </FilterOption>
  )
}

function ScrappedStatusFilter({ value, onValueChange }) {
  return (
    <FilterOption title="Scrapped Status">
      <Picker
        style={[GlobalStyle.column, GlobalStyle.column__input]}
        selectedValue={value}
        onValueChange={onValueChange}>

        <Picker.Item label="All" value="-1" />
        <Picker.Item label="Not Scrapped" value="0" />
        <Picker.Item label="Scrapped" value="1" />
      </Picker>
    </FilterOption>
  )
}

function CategoryFilter({ value, onValueChange, categories }) {
  return (
    <FilterOption title="Category">
      <Picker
        style={[GlobalStyle.column, GlobalStyle.column__input]}
        selectedValue={value}
        onValueChange={onValueChange}>

        <Picker.Item label="All" value="" />

        {categories != null && categories.map((value, index) => {
          return (
            <Picker.Item key={value.id} label={value.name} value={value.id} />
          )
        })}
      </Picker>
    </FilterOption>
  )
}

function FilterOption({ title, children }) {
  return (
    <View style={[GlobalStyle.column__flex, styles.filter__option]}>
      <Text style={GlobalStyle.column__header}>{title}</Text>
      {children}
    </View>
  )
}

const styles = StyleSheet.create({
  filter__option: {
    marginBottom: 16,
    flexBasis: 240
  },
  filter__buttons__container: {
    flexShrink: 1,
    flexGrow: 0,
    marginBottom: 16,
    flexBasis: 140,
    marginLeft: 'auto'
  },
  filter__row: {
    display: 'flex',
    flexDirection: 'row',
    flexShrink: 1,
    flexBasis: '100%',
    flexGrow: 4
  }
})