import * as SQLite from 'expo-sqlite';
import { Database } from './database';
import uuid from 'react-native-uuid';
import moment from 'moment';

export class JobDatabase extends Database {
  TABLE_NAME = "Jobs";
  TABLE_NAME_ENGINEERS = "JobEngineers";
  TABLE_NAME_ASSETS = "JobAssets";

  initialise(): Promise<void> {
    return new Promise((resolve, reject) => {
      var sql = `CREATE TABLE IF NOT EXISTS Jobs ( 
        id TEXT PRIMARY KEY,
        locationId TEXT,
        jobType INT,
        jobNumber TEXT,
        scheduledDate TEXT,
        notes TEXT,
        createdOn TEXT,
        purposeId TEXT,
        colourId TEXT,
        ramsId TEXT,
        engineerReportId TEXT,
        isCompleted INT,
        toBeSynced INT,
        yourReference TEXT
      )`;

      var sqlEngineers = `CREATE TABLE IF NOT EXISTS JobEngineers ( 
        jobId TEXT,
        engineerId TEXT
      )`;

      var sqlAssets = `CREATE TABLE IF NOT EXISTS JobAssets ( 
        id TEXT,
        jobId TEXT,
        equipmentId TEXT,
        completed INT,
        isMissing INT,
        toBeSynced INT
      )`;

      Database.getDb().transaction((tx) => {
        tx.executeSql(sql);
        tx.executeSql(sqlEngineers);
        tx.executeSql(sqlAssets);

        var checkJobsColumnSql = "PRAGMA table_info(Jobs)";
        var checkJobAssetsColumnSql = "PRAGMA table_info(JobAssets)";
        tx.executeSql(checkJobsColumnSql, [], (_, { rows: { _array } }) => {
          const columnExists = _array.some((column) => column.name === "yourReference");

          if (!columnExists) {
            var alterSql = `ALTER TABLE Jobs ADD COLUMN yourReference TEXT`;
            tx.executeSql(alterSql);
          }
        });

        tx.executeSql(checkJobAssetsColumnSql, [], (_, { rows: { _array } }) => {
          const columns = _array.map(column => column.name);

          const columnsToAdd = [];
          if (!columns.includes("isMissing")) {
            columnsToAdd.push("isMissing INT");
          }

          if (columnsToAdd.length > 0) {
            const alterSql = `ALTER TABLE JobAssets ADD COLUMN ${columnsToAdd.join(", ")}`;
            tx.executeSql(alterSql, [], () => {
              console.log("Columns added successfully.");
            }, (tx, error) => {
              console.error("Error adding columns:", error);
            });
          }

          tx.executeSql("UPDATE JobAssets SET isMissing = 0 WHERE isMissing IS NULL");
        });
      }, (error) => {
        console.log(error)
        reject();
      }, () => {
        resolve();
      })
    });
  }

  insertOrUpdateList(entities: any, toBeSynced: boolean = false): Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          tx.executeSql(`DELETE FROM ${this.TABLE_NAME_ENGINEERS} WHERE jobId = ?`, [entity.id]);
          tx.executeSql(`DELETE FROM ${this.TABLE_NAME_ASSETS} WHERE jobId = ?`, [entity.id]);
          tx.executeSql(`DELETE FROM ${this.TABLE_NAME} WHERE id = ?`, [entity.id]);

          if (entity.isDeleted || entity.isDeleted == 1) {
            continue;
          }


          var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME} (id, locationId, jobType, jobNumber, scheduledDate, notes, createdOn, purposeId, colourId, ramsId, engineerReportId, isCompleted, toBeSynced, yourReference)
            VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)
          `


          tx.executeSql(sql, [
            entity.id, entity.locationId, entity.jobType, entity.jobNumber, entity.scheduledDate, entity.notes,
            entity.createdOn, entity.purposeId, entity.colourId, entity.ramsId, entity.engineerReportId, entity.isCompleted,
            toBeSynced, entity.yourReference
          ]);
          for (let engineerId of entity.engineerIds) {
            tx.executeSql(`INSERT INTO ${this.TABLE_NAME_ENGINEERS} (jobId, engineerId) VALUES (?, ?)`, [entity.id, engineerId]);
          }

          for (let jobAsset of entity.jobAssets) {
            jobAsset.isMissing = jobAsset.isMissing ? jobAsset.isMissing : 0,
              tx.executeSql(`INSERT INTO ${this.TABLE_NAME_ASSETS} (id, jobId, equipmentId, completed, isMissing) VALUES (?, ?, ?, ?, ?)`, [jobAsset.id, jobAsset.jobId, jobAsset.equipmentId, jobAsset.completed, jobAsset.isMissing]);
          }
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  updateJobRams(jobId: any, ramsId: any): Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `
            UPDATE ${this.TABLE_NAME} SET ramsId = ?, toBeSynced = 1 WHERE id = ?
          `

        tx.executeSql(sql, [ramsId, jobId]);
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  completeJob(jobId: any): Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `
          UPDATE ${this.TABLE_NAME} SET isCompleted = 1, toBeSynced = 1 WHERE id = ?
        `

        tx.executeSql(sql, [jobId]);
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  updateEngineerReport(jobId: any, engineerReportId: any): Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `
            UPDATE ${this.TABLE_NAME} SET engineerReportId = ?, toBeSynced = 1 WHERE id = ?
          `

        tx.executeSql(sql, [engineerReportId, jobId]);
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }


  updateJobData(jobId: any, purposeId: any, colourId: any): Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `
            UPDATE ${this.TABLE_NAME} SET purposeId = ?, colourId = ?, toBeSynced = 1 WHERE id = ?
          `

        tx.executeSql(sql, [purposeId, colourId, jobId]);
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  getJobById(jobId): Promise<any> {
    return new Promise((resolve, reject) => {
      var job = null;
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT jobs.id, jobs.createdOn, jobs.locationId, companies.name as companyName,
          locations.name as locationName, scheduledDate, jobType, jobNumber,
          CASE jobType WHEN 0 THEN 'ROTE' WHEN 1 THEN 'Servicing' WHEN 2 THEN 'Breakdown' WHEN 3 THEN 'Proof Load' END AS jobTypeFormatted,
          purposeId, colourId, ramsId, engineerReportId, notes, companies.id as companyId, locations.id as locationId
          FROM jobEngineers
          INNER JOIN jobs ON jobEngineers.jobId = jobs.id 
          INNER JOIN locations ON jobs.locationId = locations.id
          INNER JOIN companies ON locations.companyId = companies.id
          WHERE jobs.id = ?
        `;

        tx.executeSql(sql, [jobId], (_, { rows: { _array } }) => {
          if (_array.length > 0) {
            job = _array[0];
          }
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(job);
      })
    })
  }

  getJobs(engineerId, searchQuery = null, modules = null): Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT jobs.id, jobs.createdOn, jobs.locationId, companies.name as companyName,
          locations.name as locationName, scheduledDate, jobType, jobNumber,
          CASE jobType WHEN 0 THEN 'ROTE' WHEN 1 THEN 'Servicing' WHEN 2 THEN 'Breakdown' WHEN 3 THEN 'Proof Load' WHEN 4 THEN 'Installation' END AS jobTypeFormatted,
          ramsId, engineerReportId, notes, jobs.yourReference
          FROM jobEngineers
          INNER JOIN jobs ON jobEngineers.jobId = jobs.id 
          INNER JOIN locations ON jobs.locationId = locations.id
          INNER JOIN companies ON locations.companyId = companies.id
          WHERE jobEngineers.engineerId = ? AND jobs.isCompleted = 0
        `;

        if (searchQuery != null && searchQuery != "") {
          const parsedDate = moment.utc(searchQuery, 'DD/MM/YYYY', true);

          if (parsedDate.isValid()) {
            const formattedSearchDate = parsedDate.toISOString().split('T')[0];

            sql += " AND (DATE(jobs.createdOn) = '" + formattedSearchDate +
              "' OR DATE(jobs.scheduledDate) = '" + formattedSearchDate + "')";
          } else {
            sql += " AND (lower(jobs.jobNumber) LIKE '%" + searchQuery.toLowerCase() +
              "%' OR lower(locations.name) LIKE '%" + searchQuery.toLowerCase() +
              "%' OR lower(jobs.yourReference) LIKE '%" + searchQuery.toLowerCase() +
              "%' OR lower(jobs.notes) LIKE '%" + searchQuery.toLowerCase() + "')";
          }
        }
        tx.executeSql(sql, [engineerId], (_, { rows: { _array } }) => {
          data = _array;

          if (modules != null) {
            if (modules.isRoteEnabled == false) data = data.filter(d => d.jobType != 0);
            if (modules.isServiceEnabled == false) data = data.filter(d => d.jobType != 1);
            if (modules.isBreakdownEnabled == false) data = data.filter(d => d.jobType != 2);
            if (modules.isProofLoadEnabled == false) data = data.filter(d => d.jobType != 3);
            if (modules.isInstallationEnabled == false) data = data.filter(d => d.jobType != 4);
          }
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    })
  }

  getJobAssets(jobId, searchQuery, withArchived = 0): Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT 
          equipment.id, 
          description, 
          serialNumber, 
          categories.name as categoryName, 
          manufacturers.name as manufacturersName,
          completed, 
          categories.id as categoryId 
          FROM JobAssets 
          INNER JOIN Equipment ON JobAssets.equipmentId = Equipment.id
          INNER JOIN Categories ON Equipment.categoryId = Categories.id
          INNER JOIN Manufacturers ON Equipment.manufacturerId = Manufacturers.id
          WHERE jobId = ? AND JobAssets.completed = 0 AND Equipment.scrapped = 0 AND Equipment.isArchived = ?
        `;

        if (searchQuery != null && searchQuery != "") {
          sql += " AND (lower(description) LIKE '%" + searchQuery.toLowerCase() + "%' OR lower(serialNumber) LIKE '%" + searchQuery.toLowerCase() + "%')";
        }

        tx.executeSql(sql, [jobId, withArchived], (_, { rows: { _array } }) => {
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }
  getJobAssetsWithSublocations(jobId, searchQuery, exactSn = false, completed = 0, isMissing = 0, withArchived = 0): Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
        SELECT
        equipment.id,
        description,
        serialNumber,
        categories.name AS categoryName,
        manufacturers.name AS manufacturersName,
        completed,
        categories.id AS categoryId,
        sublocations.id AS subLocationId,
        equipment.swl AS swl,
        Categories.InspectionInterval as interval,
        MasterCategories.name as masterCategoryName,
        equipment.rfid,
        Equipment.nextTestDate,
        Equipment.nextInspectionDate,
        Equipment.isArchived,
        JobAssets.isMissing,
        JobAssets.completed
        FROM JobAssets
        INNER JOIN Equipment ON JobAssets.equipmentId = Equipment.id
        INNER JOIN Categories ON Equipment.categoryId = Categories.id
        INNER JOIN MasterCategories ON Categories.masterCategoryId = MasterCategories.id
        INNER JOIN Manufacturers ON Equipment.manufacturerId = Manufacturers.id
        LEFT JOIN SubLocations ON Equipment.subLocationId = SubLocations.id 
        WHERE jobId = ? AND JobAssets.completed = ? AND JobAssets.isMissing = ? AND Equipment.scrapped = 0 AND Equipment.isArchived = ?
        `;
        if (exactSn) {
          sql += " AND serialNumber = " + "'" + searchQuery + "'";
        } else if (searchQuery != null && searchQuery != "") {
          sql += " AND (lower(description) LIKE '%" + searchQuery.toLowerCase() + "%' OR lower(serialNumber) LIKE '%" + searchQuery.toLowerCase()
            + "%' OR lower(assetNumber) LIKE '%" + searchQuery.toLowerCase() + "%' OR lower(rfid) LIKE '%" + searchQuery.toLowerCase() + "%')";
        }

        sql += `
            ORDER BY 
                CASE 
                    WHEN Equipment.nextInspectionDate IS NULL THEN 1
                    ELSE 0
                END,
                Equipment.nextInspectionDate ASC;
            `;

        tx.executeSql(sql, [jobId, completed, isMissing, withArchived], (_, { rows: { _array } }) => {
          data = _array;
          resolve(data);
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
      });
    });
  }
  getJobsForSync(): Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM Jobs WHERE toBeSynced = 1
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          data = _array;

          var sqlEngineers = `SELECT engineerId FROM ${this.TABLE_NAME_ENGINEERS} WHERE jobId = ?`;
          var sqlAssets = `SELECT * FROM ${this.TABLE_NAME_ASSETS} WHERE jobId = ?`;

          for (let job of data) {
            tx.executeSql(sqlEngineers, [job.id], (_, { rows: { _array } }) => {
              job.engineerIds = [];
              for (var engineerRow of _array) {
                job.engineerIds.push(engineerRow.engineerId);
              }
            });

            tx.executeSql(sqlAssets, [job.id], (_, { rows: { _array } }) => {
              job.jobAssets = _array;
            });
          }
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  getJobAssetsForSync(): Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT id, equipmentId, completed, jobId FROM JobAssets WHERE toBeSynced = 1
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  updateJobToBeSynced(id, toBeSynced): Promise<boolean> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `
          UPDATE Jobs SET toBeSynced = ? WHERE id = ?
        `;

        tx.executeSql(sql, [toBeSynced, id], (_, { rows: { _array } }) => {

        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(true);
      })
    });
  }

  updateJobAssetToBeSynced(id, toBeSynced): Promise<boolean> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `
          UPDATE JobAssets SET toBeSynced = ? WHERE id = ?
        `;

        tx.executeSql(sql, [toBeSynced, id], (_, { rows: { _array } }) => {

        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(true);
      })
    });
  }

  setJobAssetCompleted(id, completed): Promise<boolean> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `
          UPDATE JobAssets SET completed = ? WHERE id = ?
        `;

        tx.executeSql(sql, [completed, id], (_, { rows: { _array } }) => {

        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(true);
      })
    });
  }

  createJobAsset(equipmentId, jobId): Promise<boolean> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `
          INSERT INTO JobAssets (id, equipmentId, completed, isMissing, jobId, toBeSynced)
          VALUES (?, ?, 0, 0, ?, 1)
        `;

        tx.executeSql(sql, [uuid.v4(), equipmentId, jobId], (_, { rows: { _array } }) => {

        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(true);
      })
    });
  }

  getAllEquipmentWithSerialForTheCompany(companyId, searchQuery, exactSn): Promise<any> {
    return new Promise((resolve, reject) => {
      let data = [];
      Database.getDb().transaction((tx) => {
        let sql = `
        SELECT
          equipment.id,
          equipment.description,
          equipment.serialNumber,
          categories.name AS categoryName,
          Categories.InspectionInterval as interval,
          equipment.swl AS swl,
          equipment.nextInspectionDate,
          Locations.name AS location
        FROM Equipment
        INNER JOIN Categories ON Equipment.categoryId = Categories.id
        INNER JOIN Locations ON Equipment.LocationId = Locations.id
        WHERE Locations.CompanyId = ? AND Equipment.scrapped = 0 AND Equipment.isArchived = 0
        `;
        if (exactSn) {
          sql += " AND serialNumber = " + "'" + searchQuery + "'";
        } else if (searchQuery != null && searchQuery !== "") {
          sql += " AND (lower(description) LIKE '%" + searchQuery.toLowerCase() + "%' OR lower(serialNumber) LIKE '%" + searchQuery.toLowerCase()
            + "%' OR lower(assetNumber) LIKE '%" + searchQuery.toLowerCase() + "%' OR lower(rfid) LIKE '%" + searchQuery.toLowerCase() + "%')";
        }

        tx.executeSql(sql, [companyId], (_, { rows: { _array } }) => {
          data = _array;

          let jobPromises: Promise<void>[] = data.map((equipment) => {
            return new Promise<void>((resolveJob, rejectJob) => {
              const jobSql = `SELECT jobId FROM ${this.TABLE_NAME_ASSETS} 
                              INNER JOIN ${this.TABLE_NAME} ON JobAssets.jobId = Jobs.id 
                              WHERE equipmentId = ? AND Jobs.isCompleted = 0 AND Jobs.toBeSynced = 0 `;
              tx.executeSql(jobSql, [equipment.id], (_, { rows: { _array: jobArray } }) => {
                equipment.currentJobs = jobArray.map(job => job.jobId);
                resolveJob();
              }, rejectJob);
            });
          });

          Promise.all(jobPromises).then(() => {
            resolve(data);
          }).catch((error) => {
            console.log(error);
            reject(error);
          });
        }, (error) => {
          console.log(error);
          reject(error);
        });
      });
    });
  }


  removeAssetFromJob(jobId, equipmentId): Promise<boolean> {
    return new Promise((resolve, reject) => {

      Database.getDb().transaction((tx) => {
        var sql = `
          DELETE FROM JobAssets WHERE jobId = ? AND equipmentId = ?
        `;

        tx.executeSql(sql, [jobId, equipmentId], (_, { rows: { _array } }) => {
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(true);
      })
    });
  }
}