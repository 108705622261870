import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { ModulesDatabase } from '@store/services/database/modules-database';
import { setUserModules } from '@store/slices/user-slice';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import AppDashboardScreen from './Dashboard/AppDashboard';
import JobManageScreen from './Dashboard/JobManage';
import BreakdownInspect from './Inspect/Breakdown/BreakdownInspect';
import EquipmentDetailsScreen from './Inspect/EquipmentDetails';
import EquipmentManageScreen from './Inspect/EquipmentManage';
import EquipmentViewFilesScreen from './Inspect/EquipmentViewFiles';
import ERSTRScreen from './Inspect/ERSTR/ERSTR';
import JobAssetsScreen from './Inspect/JobAssets';
import ProofLoadInspect from './Inspect/ProofLoad/ProofLoadInspect';
import RAMSScreen from './Inspect/RAMS/RAMSScreen';
import RoteInspect from './Inspect/Rote/RoteInspect';
import ServiceInspect from './Inspect/Servicing/ServiceInspect';
import InstallationInspect from './Inspect/Installation/InstallationInspect';

const Stack = createNativeStackNavigator();

export default function AppNavigator({ navigation }: { navigation: any }) {
  const dispatch = useDispatch();

  useEffect(() => {
    const loadModules = async () => {
      const modulesDatabase = new ModulesDatabase();
      const modules = await modulesDatabase.getModules();
      dispatch(setUserModules(modules));
    }

    loadModules();
  }, []);

  return (
    <Stack.Navigator>
      <Stack.Screen name="Dashboard" component={AppDashboardScreen} options={{ headerShown: false, title: 'Dashboard' }} />
      <Stack.Screen name="JobManage" component={JobManageScreen} options={{ headerShown: false, title: 'Job Manage' }} />
      <Stack.Screen name="JobAssets" component={JobAssetsScreen} options={{ headerShown: false, title: 'Job Assets' }} />
      <Stack.Screen name="RAMS" component={RAMSScreen} options={{ headerShown: false, title: 'PoWRA' }} />
      <Stack.Screen name="EquipmentDetails" component={EquipmentDetailsScreen} options={{ headerShown: false, title: 'Job Details' }} />
      <Stack.Screen name="EquipmentViewFiles" component={EquipmentViewFilesScreen} options={{ headerShown: false, title: 'Job Details' }} />
      <Stack.Screen name="EquipmentManage" component={EquipmentManageScreen} options={{ headerShown: false, title: 'Job Details' }} />
      <Stack.Screen name="RoteInspect" component={RoteInspect} options={{ headerShown: false, title: 'Rote Inspect' }} />
      <Stack.Screen name="ServiceInspect" component={ServiceInspect} options={{ headerShown: false, title: 'Service Inspect' }} />
      <Stack.Screen name="BreakdownInspect" component={BreakdownInspect} options={{ headerShown: false, title: 'Breakdown Inspect' }} />
      <Stack.Screen name="ProofLoadInspect" component={ProofLoadInspect} options={{ headerShown: false, title: 'Proof Load Inspect' }} />
      <Stack.Screen name="ERSTR" component={ERSTRScreen} options={{ headerShown: false, title: 'ERSTR' }} />
      <Stack.Screen name="InstallationInspect" component={InstallationInspect} options={{ headerShown: false, title: 'Installation Inspect' }} />
    </Stack.Navigator>
  )
};