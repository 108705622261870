export enum PermissionCategories {
  Administration = "Administration",
  Reports = "Reports",
  UserManagement = "User Management",
  Permissions = "User Permissions"
}

export enum AdministrationPermissions {
  JobManagement = "job-management-menu",
  JobApproval = "job-approval-menu",
  CompanyManagement = "company-management-menu",
  LocationManagement = "location-management-menu",
  CategoryManagement = "category-management-menu",
  ManufacturersManagement = "manufacturers-management-menu",
  EquipmentManagement = "equipment-management-menu",
  BreakdownParts = "breakdown-parts-menu",
  ServicingQuestions = "servicing-questions-menu",
  InstallationQuestions = "installation-questions-menu",
  ColoursManagement = "colours-management-menu",
  EmailsManagement = "emails-management-menu",
  PermissionsManagement = "permissions-management"
}

export enum ReportsPermissions {
  AssetRegister = "asset-register",
  Inspections = "inspections-reports",
  RepairBreakdown = "breakdown-reports",
  Servicing = "servicing-reports",
  Erstr = "erstr-reports",
  Powra = "powra-reports",
  ProofLoads = "proff-loads-reports",
  Installations = "installation-reports"
}

export enum UserManagementPermissions {
  WebUsersManagement = "web-users-management",
  EngineersManagement = "engineers-management"
}

export enum JobManagementPermissions {
  AddJob = "add-job",
  ApproveJob = "approve-job"
}

export enum EquipmentManagementPermissions {
  AddEquipment = "add-equipment",
  MassUpload = "mass-upload"
}