import { baseApi, PagedTableDto } from './base-api'

export const equipmentApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAssetRegister: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `equipment/getassetregister?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    getAssetRegisterCsv: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `equipment/getassetregistercsv?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
        responseType: 'blob'
      }),
      transformResponse: (response, meta) => {
        return response;
      }
    }),
    getEquipments: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `equipment/getequipments?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    getEquipment: build.query<any, any>({
      query: (equipmentId) => ({
        url: `equipment/getequipment/${equipmentId}`,
      }),
    }),
    getEquipmentFiles: build.query<any, any>({
      query: (equipmentId) => ({
        url: `equipment/getequipmentfiles?equipmentId=${equipmentId}`,
      }),
    }),
    addFile: build.mutation<any, any>({
      query: (body) => ({
        url: 'equipment/postequipmentfile',
        method: 'post',
        body: body
      }),
    }),
    removeFile: build.mutation<any, any>({
      query: (body) => ({
        url: `equipment/removeequipmentfile?equipmentId=${body.equipmentId}&fileName=${body.fileName}`,
        method: 'delete',
        body: body
      }),
    }),
    createEquipment: build.mutation<any, any>({
      query: (body) => ({
        url: 'equipment/addequipment',
        method: 'post',
        body: body
      }),
    }),
    updateEquipment: build.mutation<any, any>({
      query: (body) => ({
        url: `equipment/updateequipment/${body.id}`,
        method: 'put',
        body: body
      }),
    }),
    syncEquipment: build.mutation<any, any>({
      query: (dto) => ({
        url: `equipment/getequipmentforsync?lastmodifiedon=${dto.lastModifiedOn}&downloaded=${dto.downloaded}&amountToFetch=${dto.amountToFetch}`,
      })
    }),
    syncLoadTypes: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `equipment/getloadtypes?lastmodifiedon=${lastModifiedOn}`,
      })
    }),
    syncLoadUnits: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `equipment/getloadunits?lastmodifiedon=${lastModifiedOn}`,
      })
    }),
    transferEquipment: build.mutation<any, any>({
      query: (body) => ({
        url: `equipment/transferequipment`,
        method: 'put',
        body: body
      }),
    }),
    getEquipmentByCompanyId: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `equipment/getEquipmentForLocationList?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
  }),
  overrideExisting: true,
})

export const {
  useGetEquipmentsQuery, useGetEquipmentQuery, useCreateEquipmentMutation,
  useUpdateEquipmentMutation, useGetAssetRegisterQuery, useAddFileMutation,
  useGetEquipmentFilesQuery, useRemoveFileMutation, useTransferEquipmentMutation,
  useGetAssetRegisterCsvQuery, useGetEquipmentByCompanyIdQuery
} = equipmentApi