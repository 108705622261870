import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Feather } from '@expo/vector-icons';
import { Foundation } from '@expo/vector-icons';
import { addBasketInspection, clearBasket, removeBasketInspection } from '@store/slices/basket-slice';
import { ScrollView } from 'react-native-gesture-handler';
import { ReportableService } from '@store/services/reportable/reportable.service';
import * as JSZip from 'jszip';
import { PDFDocument } from 'pdf-lib'
//@ts-ignore
import { RootState } from '@store/';
import GlobalStyle from '../../../constants/GlobalStyle';
import Spinner from 'react-native-loading-spinner-overlay';
import { inspectionApi } from '@store/services/api/inspection-api';
import { store } from '@store/index';
import { breakdownApi } from '@store/services/api/breakdown-api';
import { serviceApi } from '@store/services/api/service-api';
import { erstrApi } from '@store/services/api/erstr-api';
import { ramsApi } from '@store/services/api/rams-api';
import { proofLoadApi } from '@store/services/api/proof-load-api';
import { installationApi } from '@store/services/api/installation-api';

export const Basket = ({ setOnBasketClose, onBasketClose }) => {
  const dispatch = useDispatch();
  const inspections = useSelector((state: RootState) => state.basket.inspections);
  const [showList, setShowList] = useState<boolean>(false);
  const [loading, setLoading] = useState<any>(false);

  useEffect(() => {
    dispatch(clearBasket());
  }, []);

  useEffect(() => {
    if (inspections.length == 0) { setShowList(false); }
  }, [inspections])

  const toggleList = () => {
    if (inspections.length == 0) { return; }
    setShowList(!showList);
  }

  const removeFromBasket = (id) => {
    dispatch(removeBasketInspection(id));
  }

  const clearAllFromBasket = () => {
    dispatch(clearBasket());
  }

  async function splitIntoBatches(items, batchSize) {
    let batches = [];
    for (let i = 0; i < items.length; i += batchSize) {
      batches.push(items.slice(i, i + batchSize));
    }
    return batches;
  }

  const processBatch = async (batch, pdfDoc) => {
    for (let inspection of batch) {
      const base64 = await getBase64(inspection);
      const pdf = await PDFDocument.load(base64.data);
      const copiedPages = await pdfDoc.copyPages(pdf, pdf.getPageIndices());
      copiedPages.forEach(page => pdfDoc.addPage(page));
    }
  };

  const downloadPdf = async () => {
    setLoading(true);
    const batchSize = 20;
    const batches = await splitIntoBatches(inspections, batchSize);

    const pdfDoc = await PDFDocument.create();

    for (const batch of batches) {
      await processBatch(batch, pdfDoc);
    }

    const mergedPdfFile = await pdfDoc.save();
    const blob = new Blob([mergedPdfFile], { type: "application/pdf" });
    const fileSaver = require('file-saver');
    fileSaver.saveAs(blob, 'Reports.pdf');

    setLoading(false);
  };


  const downloadBatchRoteTableBasket = async () => {
    setLoading(true);
    var reportableService = new ReportableService();

    const queryString = inspections
      .map(inspection => `${encodeURIComponent(inspection.id)}`)

    const data = await reportableService.downloadBatchRoteTableReportBasket(queryString);
    if (data != null) {
      const fileSaver = require('file-saver');
      fileSaver(data.data, data.filename);
      setLoading(false);
    }
  }

  const downloadZip = async () => {
    setLoading(true);

    const batchSize = 20;
    const totalInspections = inspections.length;
    let currentBatchStart = 0;

    //@ts-ignore
    const zip = new JSZip();

    const createZipBatch = async (startIndex, endIndex) => {
      const batchPromises = [];

      for (let i = startIndex; i < endIndex; i++) {
        const inspection = inspections[i];
        batchPromises.push(
          (async () => {
            try {
              const base64 = await getBase64(inspection);
              const fileName = inspection.serialNumber.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') + " - " + (i + 1);

              zip.file(`${fileName}.pdf`, base64.data, {
                base64: true,
                compression: "DEFLATE",
                compressionOptions: { level: 9 }
              });
            } catch (error) {
              console.error(`Failed to process inspection ${inspection.serialNumber}`, error);
            }
          })()
        );
      }

      await Promise.all(batchPromises);
    };

    try {
      while (currentBatchStart < totalInspections) {
        const endBatchIndex = Math.min(currentBatchStart + batchSize, totalInspections);
        await createZipBatch(currentBatchStart, endBatchIndex);
        currentBatchStart = endBatchIndex;
      }

      const content = await zip.generateAsync({ type: "blob" }, () => {
      });

      const fileSaver = require('file-saver');
      fileSaver(content, 'Reports.zip');
    } catch (error) {
      console.error('Failed to generate or save the zip file:', error);
    } finally {
      setLoading(false);
    }
  };

  const getBase64 = async (inspection) => {
    var reportableService = new ReportableService();
    switch (inspection.type) {
      case "breakdown":
        return await reportableService.downloadBreakdown(inspection.id, true);
      case "erstr":
        return await reportableService.downloadERSTR(inspection.id, true);
      case "proofload":
        return await reportableService.downloadProofLoad(inspection.id, true);
      case "rams":
        return await reportableService.downloadRAMS(inspection.id, true);
      case "service":
        return await reportableService.downloadService(inspection.id, true);
      case "installation":
        return await reportableService.downloadInstallation(inspection.id, true);
      default:
        switch (inspection.masterCategoryName) {
          case "PPE":
            return await reportableService.downloadPPE(inspection.id, true);
          case "Marine 2006":
            return await reportableService.downloadMarine(inspection.id, true);
          case "PUWER 1998":
            return await reportableService.downloadMachine(inspection.id, true);
          default:
            return await reportableService.downloadRote(inspection.id, true);
        }
    }
  }

  const archive = async () => {
    if (confirm(`Are you sure you want to archive selected inspections?`)) {
      await Promise.all(inspections.map(async inspection => {
        switch (inspection.type) {
          case "breakdown":
            return await store.dispatch(breakdownApi.endpoints.archiveBreakdownInspection.initiate(inspection.id)) as any;
          case "erstr":
            return await store.dispatch(erstrApi.endpoints.archiveEngineerReport.initiate(inspection.id)) as any;
          case "proofload":
            return await store.dispatch(proofLoadApi.endpoints.archiveProofLoad.initiate(inspection.id)) as any;
          case "rams":
            return await store.dispatch(ramsApi.endpoints.archiveRams.initiate(inspection.id)) as any;
          case "service":
            return await store.dispatch(serviceApi.endpoints.archiveServicingInspection.initiate(inspection.id)) as any;
          case "installation":
            return await store.dispatch(installationApi.endpoints.archiveInstallationInspection.initiate(inspection.id)) as any;
          default:
            await store.dispatch(inspectionApi.endpoints.archiveInspection.initiate(inspection.id)) as any;
        }
      }))
    };
    dispatch(clearBasket());
    setShowList(!showList);
    setOnBasketClose(!onBasketClose);
  }

  return (
    <View style={styles.basket__container}>
      <Spinner
        visible={loading}
        textContent={'Downloading...'}
        textStyle={GlobalStyle.spinnerTextStyle}
      />
      <View style={styles.basket}>
        <TouchableOpacity style={styles.title} onPress={() => toggleList()}>
          <Feather name="shopping-cart" size={24} color="black" />
          <Text style={{ fontWeight: 'bold', marginLeft: 10 }}>Basket</Text>
          <View style={{ flex: 1 }}></View>
          <Text><Text style={{ fontWeight: 'bold' }}>{inspections.length}</Text><Text> Items</Text></Text>
        </TouchableOpacity>
      </View>

      {showList &&
        <View style={styles.basket__list}>
          <ScrollView>
            {inspections.map((inspection) => {
              return (
                <View style={styles.basket__row} key={inspection.id}>
                  <Text style={{ fontWeight: 'bold' }}>{inspection.serialNumber}</Text>
                  <View style={{ flex: 1 }}></View>
                  <TouchableOpacity onPress={() => removeFromBasket(inspection.id)}>
                    <Feather name="trash" size={20} color="red" />
                  </TouchableOpacity>
                </View>
              )
            })}
          </ScrollView>

          <View style={styles.action__buttons}>
            <TouchableOpacity style={styles.action__button} onPress={downloadZip}>
              <Text style={styles.action__button__text}>ZIP</Text>
            </TouchableOpacity>

            <TouchableOpacity style={styles.action__button} onPress={downloadPdf}>
              <Text style={styles.action__button__text}>PDF</Text>
            </TouchableOpacity>

            <TouchableOpacity style={styles.action__button} onPress={downloadBatchRoteTableBasket}>
              <Text style={styles.action__button__text}>Summary</Text>
            </TouchableOpacity>

            <TouchableOpacity style={styles.action__button} onPress={archive}>
              <Text style={styles.action__button__text}>Archive</Text>
            </TouchableOpacity>

            <TouchableOpacity style={[styles.action__button, styles.action__clear__button]} onPress={clearAllFromBasket}>
              <Text style={styles.action__button__text}>CLEAR</Text>
            </TouchableOpacity>
          </View>
        </View>
      }
    </View>
  )
}

export const BasketIcon = ({ inspectionId, serialNumber, type, masterCategoryName }) => {
  const dispatch = useDispatch();
  const inspections = useSelector((state: RootState) => state.basket.inspections);

  const inBasket = (id) => {
    return inspections.filter(i => i.id == id).length > 0;
  }

  const addToBasket = () => {
    dispatch(addBasketInspection({
      id: inspectionId,
      serialNumber: serialNumber,
      type: type,
      masterCategoryName: masterCategoryName
    }))
  }

  const removeFromBasket = () => {
    dispatch(removeBasketInspection(inspectionId));
  }


  if (inBasket(inspectionId)) {
    return (
      <TouchableOpacity onPress={() => removeFromBasket()}>
        <Foundation name="minus" size={24} color="black" />
      </TouchableOpacity>
    )
  }

  return (
    <TouchableOpacity onPress={() => addToBasket()}>
      <Foundation name="plus" size={24} color="black" />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  basket__container: {
    position: 'relative',
    zIndex: 999998
  },

  basket: {
    backgroundColor: '#FFF',
    padding: 10,
    marginBottom: 10,
    width: 375,

    flexDirection: 'column',
    borderWidth: 1,
    borderColor: '#CECECE'
  },

  title: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  basket__list: {
    position: 'absolute',
    top: 50,
    left: 0,
    zIndex: 9999999,
    backgroundColor: '#FFF',
    width: 375,
    maxHeight: 300,
    borderWidth: 1,
    borderColor: '#CECECE',
  },

  basket__row: {
    backgroundColor: '#f5f2f2',
    padding: 10,
    margin: 5,

    flexDirection: 'row',
    alignItems: 'center'
  },

  action__buttons: {
    flexDirection: 'row',
    borderTopWidth: 2,
    borderTopColor: '#CECECE',
    paddingTop: 5,
    paddingBottom: 5
  },

  action__button: {
    flex: 1,
    backgroundColor: 'black',
    margin: 3,
    borderRadius: 5,
    textAlign: 'center',
  },

  action__button__text: {
    color: '#FFF',
    padding: 5,
    textAlign: 'center'
  },

  action__clear__button: {
    backgroundColor: 'red'
  }
});