import { store } from "..";
import { companyApi } from "./api/company-api";
import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from "moment";
import { CompanyDatabase } from "./database/company-database";
import { LocationDatabase } from "./database/location-database";
import { locationApi } from "./api/location-api";
import { categoryApi } from "./api/category-api";
import { CategoryDatabase } from "./database/category-database";
import { ManufacturerDatabase } from "./database/manufacturer-database";
import { manufacturerApi } from "./api/manufacturer-api";
import { EquipmentDatabase } from "./database/equipment-database";
import { equipmentApi } from "./api/equipment-api";
import { JobDatabase } from "./database/job-database";
import { ColourDatabase } from "./database/colour-database";
import { PurposeDatabase } from "./database/purpose-database";
import { jobApi } from "./api/job-api";
import { purposeApi } from "./api/purpose-api";
import { colourApi } from "./api/colour-api";
import { RAMSDatabase } from "./database/rams-database";
import { ramsApi } from "./api/rams-api";
import { serviceApi } from "./api/service-api";
import { ServiceDatabase } from "./database/service-database";
import { BreakdownDatabase } from "./database/breakdown-database";
import { breakdownApi } from "./api/breakdown-api";
import { InspectionDatabase } from "./database/inspection-database";
import { inspectionApi } from "./api/inspection-api";
import { EngineerReportDatabase } from "./database/engineer-report-database";
import { engineerReportApi } from "./api/engineer-report";
import { ProofLoadDatabase } from "./database/proof-load-database";
import { proofLoadApi } from "./api/proof-load-api";
import { UserDatabase } from "./database/user-database";
import { ModulesDatabase } from "./database/modules-database";
import { customerApi } from "./api/customer-api";
import { updateApi } from "./api/updater-api";
import { useDispatch } from "react-redux";
import { addToCurrentCount, setTotalCount } from "@store/slices/updater-slice";
import { InstallationDatabase } from "./database/installation-database";
import { installationApi } from "./api/installation-api";

export class SyncService {

  fullSync: boolean = false;
  totalCount: number = 0;

  private getUpdateTime = async (key) => {
    if (this.fullSync) {
      return "2000-01-01";
    }
    var lastUpdateTime = '2000-01-01';
    var lastUpdateTimeJson = await AsyncStorage.getItem(key);
    if (lastUpdateTimeJson != null && lastUpdateTimeJson != "") {
      lastUpdateTime = moment(lastUpdateTimeJson, "YYYY/MM/DD HH:mm:ss").add(-90, 'minutes').format("YYYY-MM-DD HH:mm:ss");
    }

    return lastUpdateTime;
  }

  private setUpdateTime = async (key, time) => {
    await AsyncStorage.setItem(key, time);
  }

  private syncModules = async (dispatcher) => {
    try {
      var modulesDatabase = new ModulesDatabase();
      var result = await store.dispatch(customerApi.endpoints.getModules.initiate(null)) as any;
      var data = result.data;
      if (data.length > 0) {
        await modulesDatabase.insertOrUpdateList(data);
        dispatcher(addToCurrentCount(data.length));
      }

      console.log("Finished syncing modules: " + data.length);
    } catch {
      console.log("Error syncing Modules");
    }
  }

  private syncCompanies = async (dispatcher) => {
    try {
      var companyDatabase = new CompanyDatabase();
      const key = "companies-update-time";

      var lastSyncedTime = await this.getUpdateTime(key);

      var data = [];
      var downloaded = 0;
      var amountToFetch = 5000;

      var dataDownloaded = 0;

      do {
        var dto = { lastModifiedOn: lastSyncedTime, downloaded: downloaded, amountToFetch: amountToFetch };
        var result = await store.dispatch(companyApi.endpoints.syncCompanies.initiate(dto)) as any;
        data = result.data;

        if (data.length > 0) {
          await companyDatabase.insertOrUpdateList(data);
        }

        downloaded += amountToFetch;
        console.log(`Downloaded ${downloaded} companies...`);

        dataDownloaded += data.length;
        dispatcher(addToCurrentCount(data.length));
      } while (data.length > 0)


      await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));

      console.log("Finished syncing companies: " + dataDownloaded);
    } catch {
      console.log("Error syncing companies");
    }
  }

  private syncLocations = async (dispatcher) => {
    try {
      var locationDatabase = new LocationDatabase();
      const key = "locations-update-time";

      var lastSyncedTime = await this.getUpdateTime(key);

      var data = [];
      var downloaded = 0;
      var amountToFetch = 5000;

      var dataDownloaded = 0;

      do {
        var dto = { lastModifiedOn: lastSyncedTime, downloaded: downloaded, amountToFetch: amountToFetch };
        var result = await store.dispatch(locationApi.endpoints.syncLocations.initiate(dto)) as any;
        data = result.data;

        if (data.length > 0) {
          await locationDatabase.insertOrUpdateList(data);
        }

        downloaded += amountToFetch;
        console.log(`Downloaded ${downloaded} locations...`);

        dataDownloaded += data.length;
        dispatcher(addToCurrentCount(data.length));
      } while (data.length > 0)


      await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));

      console.log("Finished syncing locations: " + dataDownloaded);
    } catch {
      console.log("Error syncing locations");
    }
  }

  private syncSubLocations = async (dispatcher) => {
    try {
      var locationDatabase = new LocationDatabase();
      const key = "sub-locations-update-time";

      var lastSyncedTime = await this.getUpdateTime(key);

      var data = [];
      var downloaded = 0;
      var amountToFetch = 5000;

      var dataDownloaded = 0;

      do {
        var dto = { lastModifiedOn: lastSyncedTime, downloaded: downloaded, amountToFetch: amountToFetch };
        var result = await store.dispatch(locationApi.endpoints.syncSubLocations.initiate(dto)) as any;
        data = result.data;

        if (data.length > 0) {
          await locationDatabase.insertOrUpdateSubLocationsList(data);
        }

        downloaded += amountToFetch;
        console.log(`Downloaded ${downloaded} sub locations...`);

        dataDownloaded += data.length;
        dispatcher(addToCurrentCount(data.length));
      } while (data.length > 0)


      await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));

      console.log("Finished syncing sub locations: " + dataDownloaded);
    } catch {
      console.log("Error syncing sub locations");
    }
  }

  private syncCategories = async (dispatcher) => {
    try {
      var categoryDatabase = new CategoryDatabase();
      const key = "categories-update-time";

      var lastSyncedTime = await this.getUpdateTime(key);
      var result = await store.dispatch(categoryApi.endpoints.syncCategories.initiate(lastSyncedTime)) as any;
      var data = result.data;
      await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));

      if (data.length > 0) {
        await categoryDatabase.insertOrUpdateList(data);
        dispatcher(addToCurrentCount(data.length));
      }

      console.log("Finished syncing categories: " + data.length);
    } catch {
      console.log("Error syncing categories");
    }
  }

  private syncCategoryQuestions = async (dispatcher) => {
    try {
      var database = new CategoryDatabase();
      const key = "category-questions-update-time";

      var lastSyncedTime = await this.getUpdateTime(key);
      var result = await store.dispatch(categoryApi.endpoints.syncCategoryQuestions.initiate(lastSyncedTime)) as any;
      var data = result.data;
      await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));

      if (data.length > 0) {
        await database.insertOrUpdateQuestionList(data);
        dispatcher(addToCurrentCount(data.length));
      }

      console.log("Finished syncing category questions: " + data.length);
    } catch {
      console.log("Error syncing category questions");
    }
  }

  private syncCategoryQuestionsResponses = async (dispatcher) => {
    try {
      var database = new CategoryDatabase();
      const key = "category-questions-responses-update-time";
      var lastSyncedTime = await this.getUpdateTime(key);
      var result = await store.dispatch(categoryApi.endpoints.syncCategoryQuestionsResponses.initiate(lastSyncedTime)) as any;
      var data = result.data;
      await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));

      if (data.length > 0) {
        await database.insertOrUpdateQuestionResponsesList(data);
        dispatcher(addToCurrentCount(data.length));
      }

      console.log("Finished syncing category question responses: " + data.length);
    } catch {
      console.log("Error syncing category question responses");
    }
  }

  private syncCategoryFields = async (dispatcher) => {
    try {
      var categoryDatabase = new CategoryDatabase();
      const key = "category-fields-update-time";

      var lastSyncedTime = await this.getUpdateTime(key);
      var result = await store.dispatch(categoryApi.endpoints.syncCategoryFields.initiate(lastSyncedTime)) as any;
      var data = result.data;
      await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));

      if (data.length > 0) {
        await categoryDatabase.insertOrUpdateCategoryFields(data);
        dispatcher(addToCurrentCount(data.length));
      }

      console.log("Finished syncing category fields: " + data.length);
    } catch {
      console.log("Error syncing category fields");
    }
  }

  private syncMasterCategories = async (dispatcher) => {
    try {
      var categoryDatabase = new CategoryDatabase();
      const key = "master-categories-update-time";

      var lastSyncedTime = await this.getUpdateTime(key);
      var result = await store.dispatch(categoryApi.endpoints.syncMasterCategories.initiate(lastSyncedTime)) as any;
      var data = result.data;
      await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));

      if (data.length > 0) {
        await categoryDatabase.insertOrUpdateMasterCategoriesList(data);
        dispatcher(addToCurrentCount(data.length));
      }

      console.log("Finished syncing master categories: " + data.length);
    } catch {
      console.log("Error syncing master categories");
    }
  }

  private syncManufacturers = async (dispatcher) => {
    try {
      var manufacturerDatabase = new ManufacturerDatabase();
      const key = "manufacturers-update-time";

      var lastSyncedTime = await this.getUpdateTime(key);
      var result = await store.dispatch(manufacturerApi.endpoints.syncManufacturers.initiate(lastSyncedTime)) as any;
      var data = result.data;
      await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));

      if (data.length > 0) {
        await manufacturerDatabase.insertOrUpdateList(data);
        dispatcher(addToCurrentCount(data.length));
      }

      console.log("Finished syncing manufacturers: " + data.length);
    } catch {
      console.log("Error syncing manufacturers");
    }
  }

  private syncEquipment = async (dispatcher) => {
    try {
      var equipmentDatabase = new EquipmentDatabase();
      const key = "equipment-update-time";

      var lastSyncedTime = await this.getUpdateTime(key);

      var data = [];
      var downloaded = 0;
      var amountToFetch = 1000;

      var dataDownloaded = 0;

      do {
        var dto = { lastModifiedOn: lastSyncedTime, downloaded: downloaded, amountToFetch: amountToFetch };
        var result = await store.dispatch(equipmentApi.endpoints.syncEquipment.initiate(dto)) as any;
        data = result.data;

        if (data.length > 0) {
          await equipmentDatabase.insertOrUpdateList(data, false);
        }

        downloaded += amountToFetch;
        console.log(`Downloaded ${downloaded} equipment...`);

        dataDownloaded += data.length;

        dispatcher(addToCurrentCount(data.length));
      } while (data.length > 0)


      await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));
      console.log("Finished syncing equipment: " + dataDownloaded);
    } catch {
      console.log("Error syncing equipment");
    }
  }

  private syncJobs = async (dispatcher) => {
    try {
      const jobDatabase = new JobDatabase();
      const key = "jobs-update-time";
      var lastSyncedTime = await this.getUpdateTime(key);

      var data = [];
      var downloaded = 0;
      var amountToFetch = 10;

      var dataDownloaded = 0;

      do {
        var dto = { lastModifiedOn: lastSyncedTime, downloaded: downloaded, amountToFetch: amountToFetch };
        const result = await store.dispatch(jobApi.endpoints.syncJobsBatched.initiate(dto)) as any;
        data = result.data;

        if (data.length > 0) {
          await jobDatabase.insertOrUpdateList(data, false);
        }

        downloaded += amountToFetch;
        console.log(`Downloaded ${downloaded} jobs...`);

        dataDownloaded += data.length;

        dispatcher(addToCurrentCount(data.length));
      } while (data.length > 0);

      await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));
      console.log("Finished syncing jobs: " + dataDownloaded);
    } catch {
      console.log("Error syncing jobs");
    }
  }

  private syncPurposes = async (dispatcher) => {
    try {
      var database = new PurposeDatabase();
      const key = "purposes-update-time";

      var lastSyncedTime = await this.getUpdateTime(key);
      var result = await store.dispatch(purposeApi.endpoints.syncPurposes.initiate(lastSyncedTime)) as any;
      var data = result.data;
      await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));

      if (data.length > 0) {
        await database.insertOrUpdateList(data);
      }

      console.log("Finished syncing purposes: " + data.length);
    } catch {
      console.log("Error syncing purposes");
    }
  }

  private syncColours = async (dispatcher) => {
    try {
      var database = new ColourDatabase();
      const key = "colours-update-time";

      var lastSyncedTime = await this.getUpdateTime(key);
      var result = await store.dispatch(colourApi.endpoints.syncColours.initiate(lastSyncedTime)) as any;
      var data = result.data;
      await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));

      if (data.length > 0) {
        await database.insertOrUpdateList(data);
        dispatcher(addToCurrentCount(data.length));
      }

      console.log("Finished syncing colours: " + data.length);
    } catch {
      console.log("Error syncing colours")
    }
  }

  private syncRAMSQuestions = async (dispatcher) => {
    try {
      var database = new RAMSDatabase();
      const key = "rams-questions-update-time";

      var lastSyncedTime = await this.getUpdateTime(key);
      var result = await store.dispatch(ramsApi.endpoints.syncRAMSQuestions.initiate(lastSyncedTime)) as any;
      var data = result.data;

      await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));

      if (data.length > 0) {
        await database.insertOrUpdateQuestionList(data);
        dispatcher(addToCurrentCount(data.length));
      }

      console.log("Finished syncing rams questions: " + data.length);
    } catch {
      console.log("Error syncing RAMS Questions");
    }
  }

  private syncServiceQuestionCategories = async (dispatcher) => {
    try {
      var database = new ServiceDatabase();
      const key = "service-question-categories-update-time";

      var lastSyncedTime = await this.getUpdateTime(key);
      var result = await store.dispatch(serviceApi.endpoints.syncServiceQuestionCategories.initiate(lastSyncedTime)) as any;
      var data = result.data;
      await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));

      if (data.length > 0) {
        await database.insertOrUpdateQuestionCategoryList(data);
        dispatcher(addToCurrentCount(data.length));
      }

      console.log("Finished syncing service question categories: " + data.length);
    } catch {
      console.log("Error syncing service question categories");
    }
  }

  private syncServiceQuestionsResponses = async (dispatcher) => {
    try {
      var database = new ServiceDatabase();
      const key = "service-questions-responses-update-time";
      var lastSyncedTime = await this.getUpdateTime(key);
      var result = await store.dispatch(serviceApi.endpoints.syncServiceQuestionsResponses.initiate(lastSyncedTime)) as any;
      var data = result.data;
      await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));

      if (data.length > 0) {
        await database.insertOrUpdateQuestionResponsesList(data);
        dispatcher(addToCurrentCount(data.length));
      }

      console.log("Finished syncing service question responses: " + data.length);
    } catch {
      console.log("Error syncing service question responses");
    }
  }

  private syncServiceQuestions = async (dispatcher) => {
    try {
      var database = new ServiceDatabase();
      const key = "service-questions-update-time";

      var lastSyncedTime = await this.getUpdateTime(key);
      var result = await store.dispatch(serviceApi.endpoints.syncServiceQuestions.initiate(lastSyncedTime)) as any;
      var data = result.data;
      await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));

      if (data.length > 0) {
        await database.insertOrUpdateQuestionList(data);
        dispatcher(addToCurrentCount(data.length));
      }

      console.log("Finished syncing service questions: " + data.length);
    } catch {
      console.log("Error syncing service questions");
    }
  }

  private syncBreakdownCommonFaults = async (dispatcher) => {
    try {
      var database = new BreakdownDatabase();
      const key = "breakdown-common-faults-update-time";

      var lastSyncedTime = await this.getUpdateTime(key);
      var result = await store.dispatch(breakdownApi.endpoints.syncBreakdownCommonFaults.initiate(lastSyncedTime)) as any;
      var data = result.data;
      await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));

      if (data.length > 0) {
        await database.insertOrUpdateCommonFaultsList(data);
        dispatcher(addToCurrentCount(data.length));
      }

      console.log("Finished syncing breakdown common faults: " + data.length);
    } catch {
      console.log("Error syncing breakdown common faults");
    }
  }

  private syncBreakdownParts = async (dispatcher) => {
    try {
      var database = new BreakdownDatabase();
      const key = "breakdown-parts-update-time";

      var lastSyncedTime = await this.getUpdateTime(key);
      var result = await store.dispatch(breakdownApi.endpoints.syncParts.initiate(lastSyncedTime)) as any;
      var data = result.data;
      await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));

      if (data.length > 0) {
        await database.insertOrUpdatePartsList(data);
        dispatcher(addToCurrentCount(data.length));
      }

      console.log("Finished syncing breakdown parts: " + data.length);
    } catch {
      console.log("Error syncing breakdown parts");
    }
  }

  private syncEngineerReportQuestions = async (dispatcher) => {
    try {
      var database = new EngineerReportDatabase();
      const key = "engineer-report-questions-update-time";

      var lastSyncedTime = await this.getUpdateTime(key);
      var result = await store.dispatch(engineerReportApi.endpoints.syncEngineerReportQuestions.initiate(lastSyncedTime)) as any;
      var data = result.data;
      await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));

      if (data.length > 0) {
        await database.insertOrUpdateEngineerReportQuestions(data);
        dispatcher(addToCurrentCount(data.length));
      }

      console.log("Finished syncing engineer report questions: " + data.length);
    } catch {
      console.log("Error syncing engineer report questions");
    }
  }

  private syncLoadTypes = async (dispatcher) => {
    try {
      var database = new EquipmentDatabase();
      const key = "load-types-update-time";

      var lastSyncedTime = await this.getUpdateTime(key);
      var result = await store.dispatch(equipmentApi.endpoints.syncLoadTypes.initiate(lastSyncedTime)) as any;
      var data = result.data;
      await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));

      if (data.length > 0) {
        await database.insertOrUpdateLoadTypesList(data);
        dispatcher(addToCurrentCount(data.length));
      }

      console.log("Finished syncing load types: " + data.length);
    } catch {
      console.log("Error syncing load types");
    }
  }

  private syncLoadUnits = async (dispatcher) => {
    try {
      var database = new EquipmentDatabase();
      const key = "load-units-update-time";

      var lastSyncedTime = await this.getUpdateTime(key);
      var result = await store.dispatch(equipmentApi.endpoints.syncLoadUnits.initiate(lastSyncedTime)) as any;
      var data = result.data;
      await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));

      if (data.length > 0) {
        await database.insertOrUpdateLoadUnitsList(data);
        dispatcher(addToCurrentCount(data.length));
      }

      console.log("Finished syncing load units: " + data.length);
    } catch {
      console.log("Error syncing load units");
    }
  }

  private syncInstallationQuestionCategories = async (dispatcher) => {
    try {
      var database = new InstallationDatabase();
      const key = "installation-question-categories-update-time";

      var lastSyncedTime = await this.getUpdateTime(key);
      var result = await store.dispatch(installationApi.endpoints.syncInstallationQuestionCategories.initiate(lastSyncedTime)) as any;
      var data = result.data;
      await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));

      if (data.length > 0) {
        await database.insertOrUpdateQuestionCategoryList(data);
        dispatcher(addToCurrentCount(data.length));
      }

      console.log("Finished syncing installation question categories: " + data.length);
    } catch {
      console.log("Error syncing installation question categories");
    }
  }

  private syncInstallationQuestionsResponses = async (dispatcher) => {
    try {
      var database = new InstallationDatabase();
      const key = "installation-questions-responses-update-time";
      var lastSyncedTime = await this.getUpdateTime(key);
      var result = await store.dispatch(installationApi.endpoints.syncInstallationQuestionsResponses.initiate(lastSyncedTime)) as any;
      var data = result.data;
      await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));

      if (data.length > 0) {
        await database.insertOrUpdateQuestionResponsesList(data);
        dispatcher(addToCurrentCount(data.length));
      }

      console.log("Finished syncing installation question responses: " + data.length);
    } catch {
      console.log("Error syncing installation question responses");
    }
  }

  private syncInstallationQuestions = async (dispatcher) => {
    try {
      var database = new InstallationDatabase();
      const key = "installation-questions-update-time";

      var lastSyncedTime = await this.getUpdateTime(key);
      var result = await store.dispatch(installationApi.endpoints.syncInstallationQuestions.initiate(lastSyncedTime)) as any;
      var data = result.data;
      await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));

      if (data.length > 0) {
        await database.insertOrUpdateQuestionList(data);
        dispatcher(addToCurrentCount(data.length));
      }

      console.log("Finished syncing installation questions: " + data.length);
    } catch {
      console.log("Error syncing installation questions");
    }
  }

  private sendEquipment = async () => {
    var database = new EquipmentDatabase();
    var equipments = await database.getForSync();
    console.log("EQUIPMENT TO SYNC: " + equipments.length);
    for (let equipment of equipments) {
      var result = await store.dispatch(equipmentApi.endpoints.updateEquipment.initiate(equipment)) as any;
      if (!result.error) {
        await database.setToBeSynced(equipment.id, false);
      }
    }
  }

  private sendInspections = async () => {
    var database = new InspectionDatabase();
    const defaultPurposeId = "f1972aa7-04ce-4164-b388-0d00990cbc9c";

    function isValidDate(dateString: string): boolean {
      const parsedDate = new Date(dateString);
      return !isNaN(parsedDate.getTime());
    }

    var inspections = await database.getForSync();

    for (let inspection of inspections) {
      if (!isValidDate(inspection.nextInspectionDate)) {
        let date = moment.utc(inspection.inspectionDate);
        const equipmentDatabase = new EquipmentDatabase();
        const equipment = await equipmentDatabase.getById(inspection.equipmentId);
        const categoryDatabase = new CategoryDatabase();
        const category = await categoryDatabase.getById(equipment.categoryId);
        date = date.add(category.inspectionInterval, 'M');
        inspection.nextInspectionDate = date.toISOString();
      }
      if (inspection.colourId === null) {
        const database = new ColourDatabase();
        const colours = await database.getAll();
        inspection.colourId = colours[0].id;
      }
      if (inspection.purposeId === null) {
        inspection.purposeId = defaultPurposeId;
      }
      var result = await store.dispatch(inspectionApi.endpoints.sendInspection.initiate(inspection)) as any;

      if (!result.error) {
        console.log("SUCCESS");
        await database.updateInspectionToBeSynced(inspection.id, false);
      } else {
        console.log("Error sending inspection:", result.error.data.errors);
      }
    }
  }

  private sendJobs = async () => {
    const jobDatabase = new JobDatabase();
    const inspectionDatabase = new InspectionDatabase();
    const jobs = await jobDatabase.getJobsForSync();

    for (let job of jobs) {
      const result = await store.dispatch(jobApi.endpoints.sendJob.initiate(job)) as any;
      console.log("job in sync", job);

      if (!result.error) {
        console.log("SUCCESS");
        await jobDatabase.updateJobToBeSynced(job.id, false);

        if (job.isCompleted) {
          switch (job.jobType) {
            case 0:
              const roteInspections = await inspectionDatabase.getByJobId(job.id);
              for (let inspection of roteInspections) {
                await inspectionDatabase.deleteInspection(inspection.id);
              }
              break;
            case 1:
              const serviceDatabase = new ServiceDatabase();
              const serviceInspections = await serviceDatabase.getByJobId(job.id);
              for (let inspection of serviceInspections) {
                await serviceDatabase.deleteInspection(inspection.id);
              }
              break;
            case 2:
              const breakdownDatabase = new BreakdownDatabase();
              const breakdownInspections = await breakdownDatabase.getByJobId(job.id);
              for (let inspection of breakdownInspections) {
                await breakdownDatabase.deleteInspection(inspection.id);
              }
              break;
            case 3:
              const proofLoadDatabase = new ProofLoadDatabase();
              const proofloadInspections = await proofLoadDatabase.getByJobId(job.id);
              for (let inspection of proofloadInspections) {
                await proofLoadDatabase.deleteInspection(inspection.id);
              }
              break;
            case 4:
              const installationDatabase = new InstallationDatabase();
              const installationInspections = await installationDatabase.getByJobId(job.id);
              for (let inspection of installationInspections) {
                await installationDatabase.deleteInspection(inspection.id);
              }
              break;

            default:
              break;
          }
        }
      } else {
        console.log("Failed to sync job:", job.id, result.error);
      }
    }
  }


  private sendInitialJob = async () => {
    var database = new JobDatabase();
    var jobs = await database.getJobsForSync();
    for (let job of jobs) {
      const basicJob = {
        id: job.id,
        locationId: job.locationId,
        jobType: job.jobType,
        notes: job.notes,
        engineerIds: job.engineerIds,
        createdOn: job.createdOn,
        isCompleted: false,
        isDeleted: job.isDeleted,
        yourReference: job.yourReference,
        purposeId: job.purposeId,
        colourId: job.colourId
      };

      var result = await store.dispatch(jobApi.endpoints.updateJob.initiate(basicJob)) as any;

      if (!result.error) {
        console.log("SUCCESS");
      } else {
        console.log("Failed to sync initial job:", job.id, result.error);
      }
    }
  }

  private sendJobAssets = async () => {
    var database = new JobDatabase();

    var jobAssets = await database.getJobAssetsForSync();
    for (let jobAsset of jobAssets) {
      var result = await store.dispatch(jobApi.endpoints.sendJobAssets.initiate(jobAsset)) as any;

      if (!result.error) {
        console.log("SUCCESS");
        await database.updateJobAssetToBeSynced(jobAsset.id, false);
      } else {
        console.log("Failed to sync job asset:", jobAsset.id, result.error);
      }
    }
  }

  private sendRAMS = async () => {
    var database = new RAMSDatabase();

    var ramsList = await database.getForSync();

    for (let rams of ramsList) {
      var result = await store.dispatch(ramsApi.endpoints.sendRAMS.initiate(rams)) as any;
      if (!result.error) {
        console.log("SUCCESS");
        await database.updateRAMSToBeSynced(rams.id, false);
      }
    }
  }

  private sendEngineerReports = async () => {
    var database = new EngineerReportDatabase();

    var engineerReports = await database.getForSync();
    for (let engineerReport of engineerReports) {

      var result = await store.dispatch(engineerReportApi.endpoints.sendEngineerReport.initiate(engineerReport)) as any;

      if (!result.error) {
        console.log("SUCCESS");
        await database.updateEngineerReportToBeSynced(engineerReport.id, false);
      }

    }
  }

  private sendBreakdowns = async () => {
    var database = new BreakdownDatabase();

    var inspections = await database.getForSync();

    for (let inspection of inspections) {
      var result = await store.dispatch(breakdownApi.endpoints.sendBreakdownInspection.initiate(inspection)) as any;

      if (!result.error) {
        console.log("SUCCESS");
        await database.updateInspectionToBeSynced(inspection.id, false);
      }
    }
  }

  private sendServices = async () => {
    var database = new ServiceDatabase();

    var inspections = await database.getForSync();
    for (let inspection of inspections) {
      var result = await store.dispatch(serviceApi.endpoints.sendServiceInspection.initiate(inspection)) as any;
      if (!result.error) {
        console.log("SUCCESS");
        await database.updateInspectionToBeSynced(inspection.id, false);
      } else {
        console.log("Failed to sync services", result.error);
      }
    }
  }

  private sendProofLoads = async () => {
    var database = new ProofLoadDatabase();
    var inspections = await database.getForSync();
    for (let inspection of inspections) {
      var result = await store.dispatch(proofLoadApi.endpoints.sendProofLoads.initiate(inspection)) as any;
      if (!result.error) {
        console.log("SUCCESS");
        await database.updateInspectionToBeSynced(inspection.id, false);
      }
    }
  }

  private sendSubLocations = async () => {
    try {
      var database = new LocationDatabase();
      var subLocations = await database.getSubLocationsForSync();

      console.log("Sublocations TO SYNC: " + subLocations.length);
      for (let sublocation of subLocations) {
        console.log(sublocation);

        var result = await store.dispatch(locationApi.endpoints.createSubLocation.initiate(sublocation)) as any;
        if (!result.error) {
          await database.setSublocationsToBeSynced(sublocation.id, false);
          console.log("Successfully synced sublocation:", sublocation.id);
        } else {
          console.error("Failed to sync sublocation:", sublocation.id, "Error:", result.error);
        }
      }
    } catch (error) {
      console.error("Error in sendSubLocations:", error);
    }
  }

  private sendInstallations = async () => {
    var database = new InstallationDatabase();

    var inspections = await database.getForSync();
    for (let inspection of inspections) {

      var result = await store.dispatch(installationApi.endpoints.sendInstallationInspection.initiate(inspection)) as any;
      if (!result.error) {
        console.log("SUCCESS");
        await database.updateInspectionToBeSynced(inspection.id, false);
      } else {
        console.log("error sending installation", result.error.data);
      }
    }
  }

  public syncAll = async (dispatcher) => {
    this.totalCount = 0;

    await this.sendAll();

    this.totalCount = await this.getTotalCount();
    dispatcher(setTotalCount(this.totalCount));

    await this.getAll(dispatcher);
  }

  public sendAll = (): Promise<void> => {
    return new Promise(async (resolve) => {
      console.log("Sending Sublocations ");
      await this.sendSubLocations();

      console.log("Sending Equipment");
      await this.sendEquipment();

      await this.sendInitialJob();

      console.log("Sending Engineer Reports");
      await this.sendEngineerReports();

      console.log("Sending RAMS");
      await this.sendRAMS();

      console.log("Sending Jobs");
      await this.sendJobs();

      console.log("Sending Job Assets");
      await this.sendJobAssets();

      console.log("Sending Inspections");
      await this.sendInspections();

      console.log("Sending Breakdowns");
      await this.sendBreakdowns();

      console.log("Sending Services");
      await this.sendServices();

      console.log("Sending Proof Loads");
      await this.sendProofLoads();


      console.log("Sending Installations");
      await this.sendInstallations();

      resolve();
    });
  }

  public getTotalCount = (): Promise<number> => {
    return new Promise(async (resolve) => {

      let params = "";
      params += "?companiesLastModifiedOn=" + await this.getUpdateTime("companies-update-time");
      params += "&locationsLastModifiedOn=" + await this.getUpdateTime("locations-update-time");
      params += "&subLocationsLastModifiedOn=" + await this.getUpdateTime("sub-locations-update-time");
      params += "&categoriesLastModifiedOn=" + await this.getUpdateTime("categories-update-time");
      params += "&categoriesQuestionsLastModifiedOn=" + await this.getUpdateTime("category-questions-update-time");
      params += "&categoriesQuestionsResponsesLastModifiedOn=" + await this.getUpdateTime("category-questions-responses-update-time");
      params += "&categoryFieldsLastModifiedOn=" + await this.getUpdateTime("category-fields-update-time");
      params += "&masterCategoriesLastModifiedOn=" + await this.getUpdateTime("master-categories-update-time");
      params += "&manufacturersLastModifiedOn=" + await this.getUpdateTime("manufacturers-update-time");
      params += "&equipmentLastModifiedOn=" + await this.getUpdateTime("equipment-update-time");
      params += "&jobsLastModifiedOn=" + await this.getUpdateTime("jobs-update-time");
      params += "&purposesLastModifiedOn=" + await this.getUpdateTime("purposes-update-time");
      params += "&coloursLastModifiedOn=" + await this.getUpdateTime("colours-update-time");
      params += "&ramsQuestionsLastModifiedOn=" + await this.getUpdateTime("rams-questions-update-time");
      params += "&serviceQuestionCategoriesLastModifiedOn=" + await this.getUpdateTime("service-question-categories-update-time");
      params += "&serviceQuestionResponsesLastModifiedOn=" + await this.getUpdateTime("service-questions-responses-update-time");
      params += "&serviceQuestionsLastModifiedOn=" + await this.getUpdateTime("service-questions-update-time");
      params += "&breakdownCommonFaultsLastModifiedOn=" + await this.getUpdateTime("breakdown-common-faults-update-time");
      params += "&breakdownPartsLastModifiedOn=" + await this.getUpdateTime("breakdown-parts-update-time");
      params += "&engineerReportQuestionsLastModifiedOn=" + await this.getUpdateTime("engineer-report-questions-update-time");
      params += "&loadTypesModifiedOn=" + await this.getUpdateTime("load-types-update-time");
      params += "&loadTypesModifiedOn=" + await this.getUpdateTime("load-units-update-time");
      params += "&loadTypesModifiedOn=" + await this.getUpdateTime("load-units-update-time");
      params += "&installationQuestionCategoriesLastModifiedOn=" + await this.getUpdateTime("installation-question-categories-update-time");
      params += "&installationQuestionResponsesLastModifiedOn=" + await this.getUpdateTime("installation-questions-responses-update-time");
      params += "&installationQuestionsLastModifiedOn=" + await this.getUpdateTime("installation-questions-update-time");


      var result = await store.dispatch(updateApi.endpoints.getTotalsForSync.initiate(params)) as any;
      if (result.error) {
        resolve(0);
      } else {
        resolve(result.data);
      }
    })
  }

  public getAll = (dispatcher): Promise<void> => {
    return new Promise((resolve, reject) => {
      var promises = [];
      promises.push(this.syncModules(dispatcher));
      promises.push(this.syncCompanies(dispatcher));
      promises.push(this.syncLocations(dispatcher));
      promises.push(this.syncSubLocations(dispatcher));
      promises.push(this.syncMasterCategories(dispatcher));
      promises.push(this.syncCategories(dispatcher));
      promises.push(this.syncCategoryQuestions(dispatcher));
      promises.push(this.syncCategoryQuestionsResponses(dispatcher));
      promises.push(this.syncCategoryFields(dispatcher));
      promises.push(this.syncManufacturers(dispatcher));
      promises.push(this.syncEquipment(dispatcher));
      promises.push(this.syncJobs(dispatcher));
      promises.push(this.syncPurposes(dispatcher));
      promises.push(this.syncColours(dispatcher));
      promises.push(this.syncRAMSQuestions(dispatcher));
      promises.push(this.syncServiceQuestionCategories(dispatcher));
      promises.push(this.syncServiceQuestions(dispatcher));
      promises.push(this.syncServiceQuestionsResponses(dispatcher));
      promises.push(this.syncBreakdownCommonFaults(dispatcher));
      promises.push(this.syncBreakdownParts(dispatcher));
      promises.push(this.syncEngineerReportQuestions(dispatcher));
      promises.push(this.syncLoadTypes(dispatcher));
      promises.push(this.syncLoadUnits(dispatcher));
      promises.push(this.syncInstallationQuestionCategories(dispatcher));
      promises.push(this.syncInstallationQuestions(dispatcher));
      promises.push(this.syncInstallationQuestionsResponses(dispatcher));

      Promise.all(promises).then(() => {
        resolve();
      })
    });
  }
}
