import React, { useEffect, useRef, useState } from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Alert } from 'react-native';
import RoteStep1 from '@components/management/Inspection/Rote/Step1';
import RoteStep2 from '@components/management/Inspection/Rote/Step2';
import RoteStep3 from '@components/management/Inspection/Rote/Step3';
import { InspectionDatabase } from '@store/services/database/inspection-database';
import { EquipmentDatabase } from '@store/services/database/equipment-database';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { CategoryDatabase } from '@store/services/database/category-database';
import { RootState } from '@store/index';
import RoteStep4 from '@components/management/Inspection/Rote/Step4';


const Stack = createNativeStackNavigator();


export default function RoteInspect({ navigation, route }: { navigation: any, route: any }) {
  const job = useSelector((state: RootState) => state.job);
  const [category, setCategory] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [dbInspection, setDbInspection] = useState(null);
  const checklist = route.params?.categoryChecklist;
  const [context, setContext] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      const equipmentDatabase = new EquipmentDatabase();
      var equipment = await equipmentDatabase.getById(job.equipmentId);

      const categoryDatabase = new CategoryDatabase();
      var category = await categoryDatabase.getById(equipment.categoryId);

      setCategory(category);

      const inspectionDatabase = new InspectionDatabase();
      var dbInspectionData = await inspectionDatabase.getCompletedInspection(job.jobId, job.equipmentId);
      const [dbInspection] = dbInspectionData;

      if (dbInspectionData.length > 0) {
        setIsEdit(true);
        setContext("edit inspection");
        setDbInspection(dbInspection);
      }
    }

    loadData();
  }, [job]);

  const submitHandler = async (inspectionData, images, answeredQuestions) => {
    if (images == null) { images = []; }
    var inspectionDatabase = new InspectionDatabase();

    const nextTestDate = inspectionData.nextTestDate ? moment(inspectionData.nextTestDate).format("YYYY-MM-DD") : null;
    if (isEdit) {
      await inspectionDatabase.updateInspection(
        dbInspection?.id, moment(inspectionData.inspectionDate).format(), moment(inspectionData.nextInspectionDate).format("YYYY-MM-DD"), inspectionData.firstExamination, inspectionData.installedCorrectly,
        inspectionData.defects, inspectionData.immediateToPersons, inspectionData.becomeDangerToPersons, inspectionData.repairDate,
        inspectionData.repair, inspectionData.identification, inspectionData.particulars, inspectionData.safeForUse,
        job.equipmentId, job.engineerId, job.jobId, inspectionData.purposeId, inspectionData.colourId, false, images, answeredQuestions, nextTestDate);
    } else {
      await inspectionDatabase.addInspection(
        moment(inspectionData.inspectionDate).format(), moment(inspectionData.nextInspectionDate).format("YYYY-MM-DD"), inspectionData.firstExamination, inspectionData.installedCorrectly,
        inspectionData.defects, inspectionData.immediateToPersons, inspectionData.becomeDangerToPersons, inspectionData.repairDate,
        inspectionData.repair, inspectionData.identification, inspectionData.particulars, inspectionData.safeForUse,
        job.equipmentId, job.engineerId, job.jobId, inspectionData.purposeId, inspectionData.colourId, false, images, answeredQuestions, nextTestDate);
    }

    if (!inspectionData.safeForUse) {
      Alert.alert('Scrap', 'Do you want to scrap this asset?', [
        { text: 'No', style: 'cancel', onPress: () => { navigation.navigate("JobAssets"); } },
        {
          text: 'Yes', onPress: () => {
            const equipmentDatabase = new EquipmentDatabase();
            equipmentDatabase.setScrapped(job.equipmentId);
            navigation.navigate("JobAssets");
          }
        }
      ]);

      return;
    }

    navigation.navigate("JobAssets");
  }

  // Used for web
  const purposeInterval = null;

  return (
    <Stack.Navigator initialRouteName="Step1">
      <Stack.Screen
        name="Step1"
        component={RoteStep1}
        options={{ headerShown: false, title: 'Step1' }}
        initialParams={{ checklist }}
      />
      <Stack.Screen
        name="Step2"
        component={RoteStep2}
        options={{ headerShown: false, title: 'Step2', freezeOnBlur: true }}
        initialParams={{ checklist }}
      />
      <Stack.Screen
        name="Step3"
        options={{ headerShown: false, title: 'Step3', freezeOnBlur: true }}>

        {() => (
          <RoteStep3
            navigation={navigation}
            route={route}
            checklist={checklist}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name="Step4"
        options={{ headerShown: false, title: 'Step4' }}
      >
        {() => (
          <RoteStep4
            navigation={navigation}
            route={route}
            submitHandler={submitHandler}
            purposeInterval={purposeInterval}
            checklist={checklist}
            isMarineEquipment={null}
            marineEquipmentId={null}
            context={context}
            isModal={false}
          />
        )}
      </Stack.Screen>
    </Stack.Navigator>
  )
};