import { combineReducers, configureStore } from '@reduxjs/toolkit'
import userReducer from './slices/user-slice'
import roteInspectionReducer from './slices/rote-inspection-slice'
import jobReducer from './slices/job-slice'
import breakdownReducer from './slices/breakdown-slice'
import proofLoadReducer from './slices/proofload-slice'
import basketSliceReducer from './slices/basket-slice'
import updaterSliceReducer from './slices/updater-slice'
import equipmentInspectionReducer from './slices/equipment-inspection-slice'

import { setupListeners } from '@reduxjs/toolkit/query'
import { baseApi } from './services/api/base-api';

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
};

const reducers = combineReducers({
  user: userReducer,
  equipmentInspection: equipmentInspectionReducer,
  roteInspection: roteInspectionReducer,
  job: jobReducer,
  breakdown: breakdownReducer,
  proofLoad: proofLoadReducer,
  basket: basketSliceReducer,
  updater: updaterSliceReducer,
  [baseApi.reducerPath]: baseApi.reducer
})

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(baseApi.middleware),
})

setupListeners(store.dispatch)

// if (Platform.OS === 'android') {
//   const { UIManager } = NativeModules;
//   if (UIManager) {
//     // Add gesture specific events to genericDirectEventTypes object
//     // exported from UIManager native module.
//     // Once new event types are registered with react it is possible
//     // to dispatch these events to all kind of native views.
//     UIManager.genericDirectEventTypes = {
//       ...UIManager.genericDirectEventTypes,
//       onGestureHandlerEvent: { registrationName: 'onGestureHandlerEvent' },
//       onGestureHandlerStateChange: {
//         registrationName: 'onGestureHandlerStateChange',
//       },
//     };
//   }
// }

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch