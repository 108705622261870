import { Feather } from "@expo/vector-icons";
import { Picker } from "@react-native-picker/picker";
import DateTimePicker from "@web/components/navigation/controls/DateTimePicker";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { View, Text, StyleSheet, TextInput, TouchableOpacity, Alert, Platform, Dimensions } from 'react-native';
import GlobalStyle, { PRIMARY_COLOUR } from "../../constants/GlobalStyle";
import RNPickerSelect from 'react-native-picker-select';
import Checkbox from "expo-checkbox";
import { INSTALLATION_JOB_TYPE } from "../../constants/Constants";
import { equipmentApi } from "@store/services/api/equipment-api";
import { PagedTableDto } from "@store/services/api/base-api";
import { DataTable } from "react-native-paper";
import { DataTableHeader } from "@web/components/table/DatatableHeader";
import PaginationComponent from "@web/components/Pagination";

const isMobileScreen = Dimensions.get("screen").width < 480;

export const JobManageControl = ({ companies, locations, allEngineers, job, modules, onSubmit, isApp }) => {
  const [engineerId, setEngineerId] = React.useState("");
  const [engineersError, setEngineersError] = React.useState("");
  const [filteredLocations, setFilteredLocations] = React.useState(locations);
  const [filteredEquipment, setFilteredEquipment] = React.useState([]);
  const [selectedEquipment, setSelectedEquipment] = React.useState([]);
  const [page, setPage] = useState(0);
  const [sortedColumn, setSortedColumn] = React.useState<string>("serialNumber");
  const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(true);
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState<any>(10);
  const [loading, setLoading] = useState<any>(false);

  const [triggerEquipment, equipmentResult] = equipmentApi.useLazyGetEquipmentByCompanyIdQuery();

  const { control, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm({
    defaultValues: {
      id: null,
      companyId: "",
      locationId: "",
      jobType: "",
      scheduledDate: new Date(Date.now()),
      notes: "",
      jobNumber: "",
      yourReference: "",
      engineerIds: [],
      engineers: [],
      includeEquipment: false,
      equipmentIds: []
    }
  });

  React.useEffect(() => {
    if (job != null && allEngineers != null && allEngineers.length > 0) {
      setValue("id", job.id);
      setValue("companyId", job.companyId);
      setValue("locationId", job.locationId);
      setValue("jobType", job.jobType);
      setValue("scheduledDate", moment(job.scheduledDate).toDate());
      setValue("notes", job.notes);
      setValue("jobNumber", job.jobNumber);
      setValue("yourReference", job.yourReference);
      if (isInstallationJobType(job.jobType)) {
        setValue("includeEquipment", job.includeEquipment ?? false);
      } else {
        setValue("includeEquipment", true);
      }

      for (let engineerId of job.engineerIds) {
        addEngineer(engineerId);
      }
    }
  }, [job, allEngineers])

  const jobId = watch("id");
  const companyId = watch("companyId");
  const locationId = watch("locationId");
  const engineersList = watch("engineers");
  const jobType = watch("jobType");
  const includeEquipment = watch("includeEquipment")

  useEffect(() => {
    if (includeEquipment && locationId) {
      triggerEquipment(new PagedTableDto(page, { pageSize: numberOfItemsPerPage, locationId: locationId }, sortedColumn, sortedColumnAscending))
        .then(() => {
          setLoading(false);
        });
    } else {
      setFilteredEquipment([]);
    }
  }, [includeEquipment, locationId, page, sortedColumn, sortedColumnAscending, numberOfItemsPerPage]);


  useEffect(() => {
    setValue("locationId", job?.locationId ?? "");
    setFilteredLocations(locations?.filter(l => l.companyId == companyId));
  }, [companyId])

  const submit = async (data) => {
    if (!isApp && data.engineers.length === 0) {
      setEngineersError("At least one engineer must be assigned to the job.");
      return;
    }
    setEngineersError("");

    if (data.scheduledDate != null) {
      data.scheduledDate = moment(data.scheduledDate).format();
    }
    data.jobType = parseInt(data.jobType);
    const isEquipmentIncluded = isInstallationJobType(data.jobType) ? data.includeEquipment : false;
    data.includeEquipment = isEquipmentIncluded;
    data.equipmentIds = isEquipmentIncluded ? selectedEquipment : [];
    onSubmit(data);
  }

  const addEngineer = (engineerIdToAdd) => {
    if (engineerIdToAdd == null || engineerIdToAdd == "")
      return;

    var engineerIds = getValues("engineerIds");
    var formEngineers = getValues("engineers");

    const engineer = allEngineers.find(e => e.id == engineerIdToAdd);

    if (engineerIds.filter(e => e.id == engineer.id).length > 0)
      return;

    engineerIds = [...engineerIds, engineer.id];
    setValue("engineerIds", engineerIds);

    formEngineers = [...formEngineers, engineer];
    setValue("engineers", allEngineers.filter(e => engineerIds.includes(e.id)));
  }

  const removeEngineer = (engineer) => {
    var engineerIds = getValues("engineerIds");
    var formEngineers = getValues("engineers");

    engineerIds = engineerIds.filter(e => e != engineer.id);
    setValue("engineerIds", engineerIds);

    formEngineers = formEngineers.filter(e => e.id != engineer.id);
    setValue("engineers", formEngineers);
  }

  function isInstallationJobType(jobType) {
    return parseInt(jobType) === INSTALLATION_JOB_TYPE;
  }

  const toggleEquipment = (equipmentId) => {
    if (selectedEquipment.includes(equipmentId)) {
      setSelectedEquipment(selectedEquipment.filter(id => id !== equipmentId));
    } else {
      setSelectedEquipment([...selectedEquipment, equipmentId]);
    }
  };

  const clearSelection = () => {
    setSelectedEquipment([]);
  };


  return (
    <View style={styles.container}>
      <View style={GlobalStyle.form}>
        {isMobileScreen ? (
          <View style={GlobalStyle.form__row}>
            <View style={styles.form__rows}>
              <Controller
                control={control}
                name="companyId"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <View style={styles.form__rows}>
                    <Text style={styles.form__label}>Company (*)</Text>
                    {Platform.OS === "ios" ? (
                      <View style={styles.form__input__IOS}>
                        <RNPickerSelect
                          value={value}
                          onValueChange={(itemValue) => onChange(itemValue)}
                          items={companies.map((value) => ({ label: value.name, value: value.id }))}
                          style={styles}
                        />
                      </View>
                    ) :
                      (<View style={styles.form__input}>
                        <Picker
                          selectedValue={value}
                          onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                          enabled={jobId == null}
                          itemStyle={{ height: 50 }}>

                          <Picker.Item label="Please Select" value="" />

                          {companies != null && companies.map((value, index) => {
                            return (
                              <Picker.Item key={value.id} label={value.name} value={value.id} />
                            )
                          })}
                        </Picker>
                      </View>
                      )
                    }
                  </View>
                )}
              />

              {errors.companyId && <Text style={GlobalStyle.form__input__validation}>Company is required.</Text>}
            </View>

            <View style={styles.form__rows}>
              <Controller
                control={control}
                name="locationId"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <View style={styles.form__rows}>
                    <Text style={styles.form__label}>Location (*)</Text>

                    {Platform.OS === "ios" ? (
                      <View style={styles.form__input__IOS}>
                        <RNPickerSelect
                          value={value}
                          onValueChange={(itemValue) => onChange(itemValue)}
                          items={filteredLocations?.map((value) => ({ label: value.name, value: value.id }))}
                          style={styles}
                        />
                      </View>
                    ) :
                      (<View style={styles.form__input}>
                        <Picker
                          selectedValue={value}
                          onValueChange={(itemValue) => onChange(itemValue)}
                          enabled={jobId == null}
                          itemStyle={{ height: 50 }}>

                          <Picker.Item label="Please Select" value="" />

                          {filteredLocations?.map((value) => {
                            return (
                              <Picker.Item key={value.id} label={value.name} value={value.id} />
                            )
                          })}
                        </Picker>
                      </View>)
                    }
                  </View>
                )}
              />

              {errors.locationId && <Text style={GlobalStyle.form__input__validation}>Location is required.</Text>}
            </View>

            <View style={styles.form__rows}>
              <Controller
                control={control}
                name="jobType"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <View style={styles.form__rows}>
                    <Text style={styles.form__label}>Job Type (*)</Text>

                    {Platform.OS === "ios" ? (
                      <View style={styles.form__input__IOS}>
                        <RNPickerSelect
                          value={value}
                          onValueChange={(itemValue) => onChange(itemValue)}
                          items={[
                            modules.isRoteEnabled && { label: "ROTE", value: 0, key: 0 },
                            modules.isServiceEnabled && { label: "Servicing", value: 1, key: 1 },
                            modules.isBreakdownEnabled && { label: "Repair/Breakdown", value: 2, key: 2 },
                            modules.isProofLoadEnabled && { label: "Proof Load", value: 3, key: 3 },
                            modules.isInstallationEnabled && { label: "Installation", value: 4, key: 4 },
                          ].filter(Boolean)}
                          style={styles}
                        />
                      </View>
                    ) :

                      (<View style={styles.form__input}>
                        <Picker
                          selectedValue={value}
                          onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                          enabled={jobId == null}
                          itemStyle={{ height: 50 }}>

                          <Picker.Item label="Please Select" value="" />
                          {modules.isRoteEnabled == true && <Picker.Item key={0} label={"ROTE"} value={0} />}
                          {modules.isServiceEnabled == true && <Picker.Item key={1} label={"Servicing"} value={1} />}
                          {modules.isBreakdownEnabled == true && <Picker.Item key={2} label={"Repair/Breakdown"} value={2} />}
                          {modules.isProofLoadEnabled == true && <Picker.Item key={3} label={"Proof Load"} value={3} />}
                          {modules.isInstallationEnabled == true && <Picker.Item key={3} label={"Installation"} value={4} />}
                        </Picker>
                      </View>)
                    }
                  </View>
                )}
              />

              {errors.jobType && <Text style={GlobalStyle.form__input__validation}>Job Type is required.</Text>}
            </View>
            <View style={styles.form__rows}>
              {isInstallationJobType(jobType) && <View style={GlobalStyle.form__row}>
                <View style={styles.form__rows}>
                  <Controller
                    control={control}
                    name="includeEquipment"
                    render={({ field: { onChange, value } }) => (
                      <View style={styles.form__rows}>
                        <Text style={styles.form__label}>Include Equipment at this location</Text>
                        <Checkbox
                          value={value}
                          onValueChange={(newValue) => {
                            onChange(newValue);
                          }}
                          style={{ marginTop: "3%", marginLeft: "7%" }}
                        />
                      </View>
                    )}
                  />
                </View>
              </View>}
            </View>
          </View>
        ) : (
          <View>
            <View style={GlobalStyle.form__row}>
              <View style={GlobalStyle.form__column}>
                <Controller
                  control={control}
                  name="companyId"
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <View>
                      <Text style={GlobalStyle.form__column__text}>Company (*)</Text>
                      <Picker
                        style={GlobalStyle.form__column__input}
                        selectedValue={value}
                        onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                        enabled={jobId == null}
                        itemStyle={{ height: 50 }}>

                        <Picker.Item label="Please Select" value="" />

                        {companies != null && companies.map((value, index) => {
                          return (
                            <Picker.Item key={value.id} label={value.name} value={value.id} />
                          )
                        })}
                      </Picker>
                    </View>
                  )}
                />

                {errors.companyId && <Text style={GlobalStyle.form__input__validation}>Company is required.</Text>}
              </View>

              <View style={GlobalStyle.form__column}>
                <Controller
                  control={control}
                  name="locationId"
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <View>
                      <Text style={GlobalStyle.form__column__text}>Location (*)</Text>
                      <Picker
                        style={GlobalStyle.form__column__input}
                        selectedValue={value}
                        onValueChange={(itemValue) => onChange(itemValue)}
                        enabled={jobId == null}
                        itemStyle={{ height: 50 }}>

                        <Picker.Item label="Please Select" value="" />

                        {filteredLocations?.map((value) => {
                          return (
                            <Picker.Item key={value.id} label={value.name} value={value.id} />
                          )
                        })}
                      </Picker>
                    </View>
                  )}
                />

                {errors.locationId && <Text style={GlobalStyle.form__input__validation}>Location is required.</Text>}
              </View>

              <View style={GlobalStyle.form__column}>
                <Controller
                  control={control}
                  name="jobType"
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <View>
                      <Text style={GlobalStyle.form__column__text}>Job Type (*)</Text>
                      <Picker
                        style={GlobalStyle.form__column__input}
                        selectedValue={value}
                        onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                        enabled={jobId == null}
                        itemStyle={{ height: 50 }}>

                        <Picker.Item label="Please Select" value="" />
                        {modules.isRoteEnabled == true && <Picker.Item key={0} label={"ROTE"} value={0} />}
                        {modules.isServiceEnabled == true && <Picker.Item key={1} label={"Servicing"} value={1} />}
                        {modules.isBreakdownEnabled == true && <Picker.Item key={2} label={"Repair/Breakdown"} value={2} />}
                        {modules.isProofLoadEnabled == true && <Picker.Item key={3} label={"Proof Load"} value={3} />}
                        {modules.isInstallationEnabled == true && <Picker.Item key={4} label={"Installation"} value={4} />}

                      </Picker>
                    </View>
                  )}
                />

                {errors.jobType && <Text style={GlobalStyle.form__input__validation}>Job Type is required.</Text>}
              </View>
            </View>
            {(isInstallationJobType(jobType) && job == null) && <View style={GlobalStyle.form__row}>
              <View style={GlobalStyle.form__column}>
                <Controller
                  control={control}
                  name="includeEquipment"
                  render={({ field: { onChange, value } }) => (
                    <View style={styles.form__rows}>
                      <Text style={[GlobalStyle.form__column__text, { fontWeight: "bold" }]}>Include Equipment at this location</Text>
                      <Checkbox
                        value={value}
                        onValueChange={(newValue) => {
                          onChange(newValue);
                        }}
                        style={{ marginLeft: "7%" }}
                      />
                    </View>
                  )}
                />
              </View>
            </View>}
            <View>
              {Platform.OS == "web" && includeEquipment && isInstallationJobType(jobType) && (
                <View>
                  <DataTable style={GlobalStyle.table}>
                    <DataTableHeader
                      sortedColumn={sortedColumn}
                      sortedColumnAscending={sortedColumnAscending}
                      onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
                      headers={[
                        { text: 'Select', visible: true },
                        { text: 'Serial Number', sortedName: "serialNumber", visible: true },
                        { text: 'Description', sortedName: "description", visible: true },
                      ]}
                    />
                    {equipmentResult?.data?.list.map((item) => (
                      <DataTable.Row key={item.id} style={selectedEquipment.includes(item.id) ? styles.selectedRow : null}>
                        <DataTable.Cell>
                          <Checkbox
                            style={GlobalStyle.form__column__input}
                            onValueChange={() => toggleEquipment(item.id)}
                            value={selectedEquipment.includes(item.id)}
                          />
                        </DataTable.Cell>
                        <DataTable.Cell>{item.serialNumber}</DataTable.Cell>
                        <DataTable.Cell>{item.description}</DataTable.Cell>
                      </DataTable.Row>
                    ))}
                  </DataTable>
                  <PaginationComponent
                    numberOfItemsPerPage={numberOfItemsPerPage}
                    setNumberOfItemsPerPage={setNumberOfItemsPerPage}
                    page={page}
                    setPage={setPage}
                    fetchResult={equipmentResult}
                    loading={loading}
                    setLoading={setLoading}
                  />
                  <TouchableOpacity style={styles.clearSelectionButton} onPress={clearSelection}>
                    <Text style={styles.clearSelectionButtonText}>Clear Selection</Text>
                  </TouchableOpacity>
                </View>
              )}

            </View>
          </View>
        )}

        <View style={GlobalStyle.form__row}>
          {isMobileScreen ? (
            <View style={styles.form__rows}>
              <Controller
                control={control}
                name="jobNumber"
                render={({ field: { onChange, onBlur, value } }) => (
                  <View style={styles.form__rows}>
                    <Text style={styles.form__label}>Job Number</Text>
                    <TextInput
                      style={styles.form__input}
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}>
                    </TextInput>
                  </View>
                )}
              />
            </View>
          ) : (
            <View style={GlobalStyle.form__column}>
              <Controller
                control={control}
                name="jobNumber"
                render={({ field: { onChange, onBlur, value } }) => (
                  <View>
                    <Text style={GlobalStyle.form__column__text}>Job Number</Text>
                    <TextInput
                      style={GlobalStyle.form__column__input}
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}>
                    </TextInput>
                  </View>
                )}
              />
            </View>
          )}
        

          {isMobileScreen ? (
            <View style={styles.form__rows}>
              <Controller
                control={control}
                name="yourReference"
                render={({ field: { onChange, onBlur, value } }) => (
                  <View style={styles.form__rows}>
                    <Text style={styles.form__label}>Your Reference</Text>
                    <TextInput
                      style={styles.form__input}
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}>
                    </TextInput>
                  </View>
                )}
              />
            </View>
          ) : (
            <View style={GlobalStyle.form__column}>
              <Controller
                control={control}
                name="yourReference"
                render={({ field: { onChange, onBlur, value } }) => (
                  <View>
                    <Text style={GlobalStyle.form__column__text}>Your Reference</Text>
                    <TextInput
                      style={GlobalStyle.form__column__input}
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}>
                    </TextInput>
                  </View>
                )}
              />
            </View>
          )}

          {isMobileScreen ? (
            <View style={styles.form__rows}>
              <Controller
                control={control}
                name="notes"
                render={({ field: { onChange, onBlur, value } }) => (
                  <View style={styles.form__rows}>
                    <Text style={styles.form__label}>Notes</Text>
                    <TextInput
                      style={styles.form__input}
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}>
                    </TextInput>
                  </View>
                )}
              />
            </View>
          ) : (
            <View style={GlobalStyle.form__column}>
              <Controller
                control={control}
                name="notes"
                render={({ field: { onChange, onBlur, value } }) => (
                  <View>
                    <Text style={GlobalStyle.form__column__text}>Notes</Text>
                    <TextInput
                      style={GlobalStyle.form__column__input}
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}>
                    </TextInput>
                  </View>
                )}
              />
            </View>
          )}

        </View>
        {Platform.OS == "web" &&
          <View style={GlobalStyle.form__row}>
            <View style={GlobalStyle.form__column}>
              <Controller
                control={control}
                name="scheduledDate"
                render={({ field: { onChange, onBlur, value } }) => (
                  <View>
                    <Text style={GlobalStyle.form__column__text}>Scheduled Date</Text>
                    <DateTimePicker
                      style={GlobalStyle.form__column__input}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}>
                    </DateTimePicker>
                  </View>
                )}
              />
            </View>
          </View>
        }

        {allEngineers != null && allEngineers.length > 0 &&
          <View style={styles.engineer__picker__list}>
            <Text>Engineers</Text>
            <View style={styles.engineer__picker}>
              <Picker
                style={GlobalStyle.form__column__input}
                selectedValue={engineerId}
                onValueChange={(itemValue, itemIndex) => setEngineerId(itemValue)}
                itemStyle={{ height: 50 }}>

                <Picker.Item label="Please Select" value="" />

                {allEngineers != null && allEngineers.map((value, index) => {
                  return (
                    <Picker.Item key={index} label={value.name} value={value.id} />
                  )
                })}
              </Picker>

              <TouchableOpacity style={styles.engineer__picker__button} onPress={() => addEngineer(engineerId)}>
                <Text style={styles.engineer__picker__button__text}>Add</Text>
              </TouchableOpacity>
            </View>
            {engineersError && (
              <Text style={GlobalStyle.form__input__validation}>{engineersError}</Text>
            )}
            <View>
              {engineersList != null && engineersList.map((element: any) => {
                return (
                  <View style={styles.engineer__row} key={element.id}>
                    <Text style={styles.engineer__row__text}>{element.name}</Text>
                    <TouchableOpacity onPress={() => removeEngineer(element)}>
                      <Feather name="trash" size={20} color="red" />
                    </TouchableOpacity>
                  </View>
                )
              })}
            </View>
          </View>
        }

        <TouchableOpacity style={GlobalStyle.form__submit__button} onPress={handleSubmit(submit)}>
          <Text style={GlobalStyle.form__submit__button__text}>SAVE</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
};


const styles = StyleSheet.create({
  container: {
    //flex: 1,
    //position: 'relative',
    padding: 10,
  },

  engineer__picker: {
    flexDirection: 'row',
  },

  engineer__picker__button: {
    backgroundColor: PRIMARY_COLOUR,
    borderWidth: 1,
    borderColor: '#CECECE',
    marginTop: 5,
    marginLeft: 10,
  },

  engineer__picker__button__text: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    color: '#FFF'
  },

  engineer__picker__list: {
    margin: 5,
  },

  engineer__row: {
    marginTop: 5,
    marginBottom: 5,
    backgroundColor: '#f5f0f0',
    padding: 5,

    flexDirection: 'row',
    alignItems: 'center',
  },

  engineer__row__text: {
    fontFamily: 'OpenSans-Bold',
    padding: 5,
    marignRight: 10,
  },
  form__rows: {
    marginRight: 5,
    flexDirection: "row",
    alignItems: "center"
  },

  form__label: {
    fontFamily: 'OpenSans-Light',
    fontWeight: 'bold',
    paddingTop: 10,
    fontSize: 14,
    flex: 1
  },

  form__input: {
    flex: 2,
    fontFamily: 'OpenSans-Regular',
    padding: 5,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#CECECE',
    marginTop: 5,
  },
  inputIOS: {
    fontSize: 18,
    paddingVertical: 12,
    paddingHorizontal: 10,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#CECECE',
    paddingRight: 30 // to ensure the text is never behind the icon
  },
  form__input__IOS: {
    flex: 2,
    fontFamily: 'OpenSans-Regular',
    padding: 5,
    marginTop: 5,
  },
  selectedRow: {
    backgroundColor: '#d3f4ff',
  },
  clearSelectionButton: {
    backgroundColor: PRIMARY_COLOUR,
    padding: 10,
    marginTop: 10,
    alignItems: 'center',
  },
  clearSelectionButtonText: {
    color: '#FFF',
    fontWeight: 'bold',
  },


});
