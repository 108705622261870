import { convertBase64toBlob } from "@components/helpers/Extensions";
import { AntDesign, SimpleLineIcons } from "@expo/vector-icons";
import { equipmentApi } from "@store/services/api/equipment-api";
import React from "react";
import { TouchableOpacity, View } from "react-native";

export const EquipmentFiles = ({ equipmentId }: { equipmentId: any}) => {
    const [trigger, filesResult] = equipmentApi.useLazyGetEquipmentFilesQuery();

    React.useEffect(() => {
        trigger(equipmentId);
    }, [])


    const download = (file) => {
        let blob = convertBase64toBlob(file.base64, file.mimeType);

        const fileSaver = require('file-saver');
        fileSaver(blob, file.fileName);
    }
    
    return (
        <View style ={{ display: "flex", flexDirection: "row", paddingRight: 5}}>
            {filesResult?.data?.map((element: any) => {
                return (
                    <TouchableOpacity key={element.fileName} onPress={() => download(element)} >
                        <AntDesign name="pdffile1" size={20} color="#13c2c2" />
                    </TouchableOpacity>
                )
            })}
        </View>
    )
}