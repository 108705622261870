import { Checkbox, DataTable } from 'react-native-paper';
import { useState } from 'react';
import { StyleProp, ViewStyle, StyleSheet, View } from 'react-native';

type DataTableHeaderProps = {
  headers: any[];

  sortedColumn: string;
  sortedColumnAscending: boolean;
  onSelectAll?: () => void;
  onClearAll?: () => void;
  isSelectAllChecked?: boolean;
  isInspectionsPage?: boolean;
  onSorted: CallableFunction;
  style?: StyleProp<ViewStyle>;
}

export const DataTableHeader = (props: DataTableHeaderProps) => {
  const [sortedColumn, setSortedColumn] = useState(props.sortedColumn);
  const [sortedColumnAscending, setSortedColumnAscending] = useState(props.sortedColumnAscending);

  const sortHeader = (header) => {
    if (header.preventSorting) return;

    const column = header.sortedName;
    if (column == null) return;
    let ascending = sortedColumnAscending;

    if (sortedColumn.toLowerCase() == column.toLowerCase()) {
      ascending = !sortedColumnAscending;
      setSortedColumnAscending(ascending);
    } else {
      ascending = true;
      setSortedColumnAscending(true);
    }

    setSortedColumn(column);
    props.onSorted(column, ascending);
  }

  const sortHeaderOrder = () => sortedColumnAscending ? 'ascending' : 'descending';

  const handleSelectAllToggle = () => {
    if (props.isSelectAllChecked) {
      props.onClearAll();
    } else {
      props.onSelectAll();
    }
  };

  return (
    <DataTable.Header style={[props.style, { alignItems: 'center' }]}>
      { props.headers.filter(h => h.visible).map((header, i) => {
        return (
          <View style={[{ flex: header.flex != null ? header.flex : 1, flexDirection: 'row', alignItems: 'center', margin: 0 }, props.style]}>
              {(header.text === "Inspect" || (props.isInspectionsPage && header.text === "Basket")) && <Checkbox
                status={props.isSelectAllChecked ? 'checked' : 'unchecked'}
                onPress={handleSelectAllToggle}
                color={'#2C89C6'}
              />
              }
            <DataTable.Title
              key={i}
              onPress={() => sortHeader(header)} 
              sortDirection={sortedColumn?.toLowerCase() != header?.sortedName?.toLowerCase() ? null : sortHeaderOrder()}
              style={{ flex: header.flex != null ? header.flex : 1 }}>

              {header.text}

            </DataTable.Title>
          </View>
        )
      })}
    </DataTable.Header>
  )
}