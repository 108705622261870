import { useWindowDimensions } from 'react-native';
// import { createDrawerNavigator } from '@react-navigation/drawer';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { DrawerContent } from '../components/navigation/DrawerContent';

import DashboardScreen from './AuthenticatedScreens/DashboardScreen';

import { RootState } from '../../../store';
import { Header } from '../components/navigation/Header';

import Companies from './AuthenticatedScreens/Management/Companies/Companies';
import Locations from './AuthenticatedScreens/Management/Locations/Locations';
import Categories from './AuthenticatedScreens/Management/Categories/Categories';
import Manufacturers from './AuthenticatedScreens/Management/Manufacturers/Manufacturers';
import Equipment from './AuthenticatedScreens/Management/Equipment/Equipment';
import Jobs from './AuthenticatedScreens/Jobs/Jobs';
import BreakdownParts from './AuthenticatedScreens/Management/Parts/Parts';
import TabletUsers from './AuthenticatedScreens/Management/TabletUsers/TabletUsers';
import WebUsers from './AuthenticatedScreens/Management/WebUsers/WebUsers';
import InspectionScreen from './AuthenticatedScreens/Reports/Inspections';
import BreakdownInspectionScreen from './AuthenticatedScreens/Reports/Breakdowns';
import ServiceInspectionScreen from './AuthenticatedScreens/Reports/Services';
import AssetRegisterScreen from './AuthenticatedScreens/Reports/AssetRegister';
import JobApprovalScreen from './AuthenticatedScreens/Jobs/JobApproval/JobApproval';
import EngineerReportsScreen from './AuthenticatedScreens/Reports/EngineerReports';
import RAMSScreen from './AuthenticatedScreens/Reports/RAMS';
import ProofLoadsScreen from './AuthenticatedScreens/Reports/ProofLoads';
import Customers from './AuthenticatedScreens/System/Customers/Customers';
import { customerApi } from '@store/services/api/customer-api';
import { setUserModules } from '@store/slices/user-slice';
import ServiceQuestions from './AuthenticatedScreens/Management/Service-Questions/ServiceQuestions';
import { createDrawerNavigator } from '@react-navigation/drawer';
import JobApprovalAssetsScreen from './AuthenticatedScreens/Jobs/JobApproval/JobApprovalAssets';
import JobInspect from './AuthenticatedScreens/Jobs/Inspect/JobInspect';
import Colours from './AuthenticatedScreens/Management/Colours/Colours';
import PermissionsDashboard from './AuthenticatedScreens/System/Permissions/PermissionsDashboard';
import BreakdownFaults from './AuthenticatedScreens/Management/BreakdownFaults/BreakdownFaults';
import Depots from './AuthenticatedScreens/Management/Depots/Depots';
import InstallationQuestions from './AuthenticatedScreens/Management/Installation-Questions/InstallationQuestions';
import InstallationInspectionScreen from './AuthenticatedScreens/Reports/Installations';
import Emails from './AuthenticatedScreens/Management/Emails/Emails';

const Drawer = createDrawerNavigator();

export default function WebNavigator({ navigation }: { navigation: any }) {
  const dispatch = useDispatch();
  const dimensions = useWindowDimensions();
  const isLargeScreen = dimensions.width >= 768;
  const user = useSelector((state: RootState) => state.user.user);

  const [triggerModules, modulesResult] = customerApi.useLazyGetModulesQuery();

  useEffect(() => {
    if (user == null) {
      navigation.navigate("login")
    }
  }, [user])

  const screenChange = async () => {
    var result = await triggerModules(null);
    if (result.isSuccess) {
      const modules = result.data;
      dispatch(setUserModules(modules));
    }
  }

  if (!user) return null;

  return (
    <Drawer.Navigator
      screenListeners={{ state: (e) => { screenChange(); } }}
      detachInactiveScreens={true}
      defaultStatus="open"
      screenOptions={{
        drawerType: isLargeScreen ? 'permanent' : 'back',
        drawerStyle: isLargeScreen ? { width: 250 } : { width: '100%' },
        overlayColor: 'transparent',
        header: (props) => Header(props, user),
        unmountOnBlur: true
      }}
      drawerContent={(props) => <DrawerContent {...props} />}
    >
      <Drawer.Screen name="dashboard" component={DashboardScreen} options={{ title: 'Dashboard' }} />

      <Drawer.Screen name="customers" component={Customers} options={{ title: 'Customers' }} />

      <Drawer.Screen name="reports-asset-register" component={AssetRegisterScreen} options={{ title: 'Asset Register' }} />
      <Drawer.Screen name="reports-inspections" component={InspectionScreen} options={{ title: 'Inspections' }} />
      <Drawer.Screen name="reports-breakdowns" component={BreakdownInspectionScreen} options={{ title: 'Repair/Breakdown Inspections' }} />
      <Drawer.Screen name="reports-servicing" component={ServiceInspectionScreen} options={{ title: 'Servicing Inspections' }} />
      <Drawer.Screen name="reports-erstr" component={EngineerReportsScreen} options={{ title: 'Engineer Reports' }} />
      <Drawer.Screen name="reports-rams" component={RAMSScreen} options={{ title: 'PoWRA Reports' }} />
      <Drawer.Screen name="reports-proofloads" component={ProofLoadsScreen} options={{ title: 'Proof Load Reports' }} />
      <Drawer.Screen name="reports-installations" component={InstallationInspectionScreen} options={{ title: 'Installations' }} />

      <Drawer.Screen name="job-management" component={Jobs} options={{ title: 'Jobs' }} />
      <Drawer.Screen name="job-approval" component={JobApprovalScreen} options={{ title: 'Job Approval' }} />
      <Drawer.Screen name="job-approval-assets" component={JobApprovalAssetsScreen} options={{ title: 'Job Approval' }} />
      <Drawer.Screen name="job-inspect" component={JobInspect} options={{ title: 'Job Inspect' }} />

      <Drawer.Screen name="administration-companies" component={Companies} options={{ title: 'Companies' }} />
      <Drawer.Screen name="administration-locations" component={Locations} options={{ title: 'Locations' }} />
      <Drawer.Screen name="administration-depots" component={Depots} options={{ title: 'Depots' }} />
      <Drawer.Screen name="administration-categories" component={Categories} options={{ title: 'Categories' }} />
      <Drawer.Screen name="administration-manufacturers" component={Manufacturers} options={{ title: 'Manufacturers' }} />
      <Drawer.Screen name="administration-equipment" component={Equipment} options={{ title: 'Equipment' }} />
      <Drawer.Screen name="administration-breakdown-parts" component={BreakdownParts} options={{ title: 'Breakdown Parts' }} />
      <Drawer.Screen name="administration-breakdown-faults" component={BreakdownFaults} options={{ title: 'Breakdown Faults' }} />
      <Drawer.Screen name="administration-servicing-questions" component={ServiceQuestions} options={{ title: 'Service Questions' }} />
      <Drawer.Screen name="administration-installation-questions" component={InstallationQuestions} options={{ title: 'Installation Questions' }} />
      <Drawer.Screen name="administration-colours" component={Colours} options={{ title: 'Colours' }} />
      <Drawer.Screen name="administration-emails" component={Emails} options={{ title: 'Emails' }} />

      <Drawer.Screen name="administration-web-users" component={WebUsers} options={{ title: 'Web Users' }} />
      <Drawer.Screen name="administration-engineers" component={TabletUsers} options={{ title: 'Engineers' }} />
      <Drawer.Screen name="permissions" component={PermissionsDashboard} options={{ title: 'Permissions' }} />

    </Drawer.Navigator>
  );
}

