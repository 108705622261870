import * as SQLite from 'expo-sqlite';
import { Database } from './database';

export class CategoryDatabase extends Database {
  TABLE_NAME = "Categories";
  TABLE_NAME_MASTER_CATEGORIES = "MasterCategories";
  TABLE_NAME_CATEGORY_FIELDS = "CategoryFields";
  TABLE_NAME_CATEGORY_QUESTIONS = "CategoryQuestions";
  TABLE_NAME_CATEGORY_QUESTIONS_RESPONSES = "CategoryQuestionsResponses";
  

  initialise() : Promise<void> {
    return new Promise((resolve, reject) => {
      var sql = `CREATE TABLE IF NOT EXISTS Categories ( 
        id TEXT PRIMARY KEY,
        name TEXT,
        inspectionInterval INT,
        masterCategoryId TEXT,
        isServiceable INT,
        isDeleted INT
      )`;

      var sqlMasterCategories = `CREATE TABLE IF NOT EXISTS MasterCategories ( 
        id TEXT PRIMARY KEY,
        name TEXT
      )`;

      var sqlCategoryFields = `CREATE TABLE IF NOT EXISTS CategoryFields ( 
        id TEXT PRIMARY KEY,
        fieldName TEXT,
        categoryId TEXT
      )`;

      var sqlQuestions = `CREATE TABLE IF NOT EXISTS CategoryQuestions ( 
        id TEXT PRIMARY KEY,
        position INT,
        question TEXT,
        suffix TEXT,
        categoryId TEXT,
        isDeleted INT
      )`;

      var sqlQuestionsResponses = `CREATE TABLE IF NOT EXISTS CategoryQuestionsResponses ( 
        id TEXT PRIMARY KEY,
        response INT,
        commentMandatory INT,
        categoryQuestionId TEXT,
        position INT,
        isDeleted INT,
        isPassDefault INT
      )`;

      Database.getDb().transaction((tx) => {
        tx.executeSql(sql);
        tx.executeSql(sqlMasterCategories);
        tx.executeSql(sqlCategoryFields);
        tx.executeSql(sqlQuestions);
        tx.executeSql(sqlQuestionsResponses);
        
        var checkColumnSql = "PRAGMA table_info(Categories)";
        tx.executeSql(checkColumnSql, [], (_, { rows: { _array } }) => {
          const columnExists = _array.some((column) => column.name === "isServiceable");
          const deletedExists = _array.some((column) => column.name === "isDeleted");
          
          if (!columnExists) {
            var alterSql = `ALTER TABLE Categories ADD COLUMN isServiceable INT`;
            tx.executeSql(alterSql);
          }
          if (!deletedExists) {
            var alterSql = `ALTER TABLE Categories ADD COLUMN isDeleted INT`;
            tx.executeSql(alterSql);
            tx.executeSql("UPDATE Categories SET isDeleted = 0 WHERE isDeleted IS NULL");
          }
        });
        
      }, (error) => {
        console.log(error)
        reject();
      }, () => {
        resolve();
      })
    });
  }

  insertOrUpdateList(entities: any) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME} (id, name, inspectionInterval, masterCategoryId, isServiceable, isDeleted)
            VALUES (?, ?, ?, ?, ?, ?)
          `
          tx.executeSql(sql, [entity.id, entity.name, entity.inspectionInterval, entity.masterCategoryId, entity.isServiceable, entity.isDeleted]);
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  insertOrUpdateMasterCategoriesList(entities: any): Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        const sql = `
          INSERT OR REPLACE INTO ${this.TABLE_NAME_MASTER_CATEGORIES} (id, name)
          VALUES (?, ?)
        `;
        const deleteSql = `DELETE FROM ${this.TABLE_NAME_MASTER_CATEGORIES} WHERE id = ?`;
  
        entities.forEach((entity) => {
          if (entity.isDeleted) {
            tx.executeSql(deleteSql, [entity.id]);
          } else {
            tx.executeSql(sql, [entity.id, entity.name]);
          }
        });
      }, (error) => {
        console.error(error);
        reject();
      }, () => {
        resolve();
      });
    });
  }

  insertOrUpdateCategoryFields(entities: any) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME_CATEGORY_FIELDS} (id, fieldName, categoryId)
            VALUES (?, ?, ?)
          `

          tx.executeSql(sql, [entity.id, entity.fieldName, entity.categoryId]);
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  getById(categoryId) : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = null;
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM Categories WHERE id = ? ORDER BY name
        `;

        tx.executeSql(sql, [categoryId], (_, { rows: { _array } }) => {
          if (_array.length > 0)
            data = _array[0];
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  getAllCategories() : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM ${this.TABLE_NAME}
          WHERE isDeleted = 0
          ORDER BY name
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          //console.log(_array)
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    })
  }

  getAllMasterCategories() : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM ${this.TABLE_NAME_MASTER_CATEGORIES} ORDER BY name
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          //console.log(_array)
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    })
  }

  getCategoryFields() : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM ${this.TABLE_NAME_CATEGORY_FIELDS} ORDER BY fieldName
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          //console.log(_array)
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    })
  }

  insertOrUpdateQuestionList(entities: any) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME_CATEGORY_QUESTIONS} (id, position, question, suffix, categoryId, isDeleted)
            VALUES (?, ?, ?, ?, ?, ?)
          `

          tx.executeSql(sql, [
            entity.id, entity.position, entity.question, entity.suffix, entity.categoryId, entity.isDeleted
          ]);
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  insertOrUpdateQuestionResponsesList(entities: any) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME_CATEGORY_QUESTIONS_RESPONSES} (id, response, commentMandatory, categoryQuestionId, position, isPassDefault, isDeleted)
            VALUES (?, ?, ?, ?, ?, ?, ?)
          `

          tx.executeSql(sql, [
            entity.id, entity.response, entity.commentMandatory, entity.categoryQuestionId, entity.position, entity.isPassDefault, entity.isDeleted
          ]);
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  getCategoryQuestions(categoryId) : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT CategoryQuestions.id, CategoryQuestions.position as questionPosition, question, suffix, Categories.name,
          CategoryQuestions.isDeleted
          FROM CategoryQuestions
          INNER JOIN Categories ON CategoryQuestions.categoryId = Categories.id
          WHERE CategoryQuestions.categoryId = ? AND CategoryQuestions.isDeleted = 0
        `;

        tx.executeSql(sql, [categoryId], (_, { rows: { _array } }) => {
          // Load all the questions and assign where they relate
          data = _array;

          sql = `SELECT * FROM ${this.TABLE_NAME_CATEGORY_QUESTIONS_RESPONSES} WHERE isDeleted = 0`;
          tx.executeSql(sql, [], (_, { rows: { _array } }) => {
            const responses = _array;

            for (let question of data) {
              question.responses = responses.filter(r => r.categoryQuestionId == question.id);
            }
          });

          
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }
}