import { RootState } from "@store/index";
import { PagedTableDto, baseUrl } from "../api/base-api";
import { store } from '../../index';
import { inspectionApi } from "../api/inspection-api";

export class ReportableService {
  //private baseUrl = "https://reportable.corerfid.net";

  public downloadRote = async (inspectionId, base64: boolean = false) => {
    var token = store.getState().user.token;
    const response = await fetch(`${baseUrl}api/reports/exportrote?inspectionId=${inspectionId}`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${token}`
      }
    });


    if (response.status == 200) {
      var data = await response.json();
      if (base64) return { data: data.base64, filename: data.filename }
      let blob = this.convertBase64toBlob(data.base64, 'application/pdf');
      return { data: blob, filename: data.filename }
    }
  }

  public downloadRAMS = async (ramsId, base64: boolean = false) => {
    var token = store.getState().user.token;
    const response = await fetch(`${baseUrl}api/reports/exportrams?ramsId=${ramsId}`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${token}`
      }
    });

    if (response.status == 200) {
      var data = await response.json();
      if (base64) return { data: data.base64, filename: data.filename }
      let blob = this.convertBase64toBlob(data.base64, 'application/pdf');
      return { data: blob, filename: data.filename }
    }
  }

  public downloadChecklist = async (inspectionId, base64: boolean = false) => {
    var token = store.getState().user.token;
    const response = await fetch(`${baseUrl}api/reports/exportrotechecklist?inspectionId=${inspectionId}`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${token}`
      }
    });

    if (response.status == 200) {
      var data = await response.json();
      if (base64) return { data: data.base64, filename: data.filename }
      let blob = this.convertBase64toBlob(data.base64, 'application/pdf');
      return { data: blob, filename: data.filename }
    }
  }

  public downloadAssetRegister = async (locationId, base64: boolean = false) => {
    var token = store.getState().user.token;

    const response = await fetch(`${baseUrl}api/reports/exportassetregister?locationId=${locationId}`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${token}`
      }
    });

    if (response.status == 200) {
      var data = await response.json();
      if (base64) return { data: data.base64, filename: data.filename }
      let blob = this.convertBase64toBlob(data.base64, 'application/pdf');
      return { data: blob, filename: data.filename }
    }
  }
  public downloadAssetRegisterCSV = async (dto: PagedTableDto, base64: boolean = false) => {
    var token = store.getState().user.token;

    const url = `${baseUrl}api/equipment/getassetregistercsv?pageNumber=${dto.page + 1}&${dto.getFilters()}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${token}`
      }
    });

    if (response.status === 200) {
      const data = await response.blob();
      return { data, filename: 'asset_register.csv' };
    } else {
      throw new Error('Failed to fetch CSV');
    }
  }

  public downloadBatchRoteTableReport = async (params: Record<string, any>, base64: boolean = false) => {
    var token = store.getState().user.token;

    const statusParams = [
      { statusId: 1, reportName: "Passed" },
      { statusId: 0, reportName: "Failed" },
      { statusId: 2, reportName: "Not presented" },
    ];

    const downloadResults = [];

    for (const additionalParams of statusParams) {
      const newParams = { ...params, ...additionalParams };
      const url = `${baseUrl}api/reports/exportBatchRoteTableReport${this.buildQueryString(newParams)}`;

      const response = await fetch(
        url,
        {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          },
        }
      );

      if (response.status === 200) {
        var data = await response.json();
        if (base64) {
          downloadResults.push({ data: data.base64, filename: data.filename });
        } else {
          let blob = this.convertBase64toBlob(data.base64, 'application/pdf');
          downloadResults.push({ data: blob, filename: data.filename });
        }
      }
    }

    return downloadResults;
  }

  public downloadBatchRoteTableReportBasket = async (inspectionsIds, base64 = false) => {
    var token = store.getState().user.token;

    const url = `${baseUrl}api/reports/exportBatchRoteTableReportBasket`;

    const response = await fetch(
      url,
      {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(inspectionsIds)
      }
    );

    if (response.status === 200) {
      var data = await response.json();
      if (base64) return { data: data.base64, filename: data.filename };
      let blob = this.convertBase64toBlob(data.base64, 'application/pdf');
      return { data: blob, filename: data.filename };
    }
  }

  public downloadBatchPdf = async (inspectionsIds, base64 = false) => {
    var token = store.getState().user.token;

    const url = `${baseUrl}api/reports/exportBatchRotePdf`;

    const response = await fetch(
      url,
      {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(inspectionsIds)
      }
    );

    if (response.status === 200) {
      var data = await response.json();
      if (base64) return { data: data.base64, filename: data.filename };
      let blob = this.convertBase64toBlob(data.base64, 'application/pdf');
      return { data: blob, filename: data.filename };
    }
  }

  public downloadBreakdown = async (inspectionId, base64: boolean = false) => {
    var token = store.getState().user.token;
    const response = await fetch(`${baseUrl}api/reports/exportbreakdown?inspectionId=${inspectionId}`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${token}`
      }
    });

    if (response.status == 200) {
      var data = await response.json();
      if (base64) return { data: data.base64, filename: data.filename }
      let blob = this.convertBase64toBlob(data.base64, 'application/pdf');
      return { data: blob, filename: data.filename }
    }
  }

  public downloadDoC = async (equipmentId, base64: boolean = false) => {
    var token = store.getState().user.token;
    const response = await fetch(`${baseUrl}api/reports/exportdoc?equipmentId=${equipmentId}`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${token}`
      }
    });


    if (response.status == 200) {
      var data = await response.json();
      if (base64) return { data: data.base64, filename: data.filename }
      let blob = this.convertBase64toBlob(data.base64, 'application/pdf');
      return { data: blob, filename: data.filename }
    }
  }

  public downloadERSTR = async (erstrId, base64: boolean = false) => {
    var token = store.getState().user.token;
    const response = await fetch(`${baseUrl}api/reports/exporterstr?erstrId=${erstrId}`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${token}`
      }
    });

    if (response.status == 200) {
      var data = await response.json();
      if (base64) return { data: data.base64, filename: data.filename }
      let blob = this.convertBase64toBlob(data.base64, 'application/pdf');
      return { data: blob, filename: data.filename }
    }
  }

  public downloadProofLoad = async (inspectionId, base64: boolean = false) => {
    var token = store.getState().user.token;
    const response = await fetch(`${baseUrl}api/reports/exportproofload?inspectionId=${inspectionId}`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${token}`
      }
    });

    if (response.status == 200) {
      var data = await response.json();
      if (base64) return { data: data.base64, filename: data.filename }
      let blob = this.convertBase64toBlob(data.base64, 'application/pdf');
      return { data: blob, filename: data.filename }
    }
  }

  public downloadPPE = async (inspectionId, base64: boolean = false) => {
    var token = store.getState().user.token;
    const response = await fetch(`${baseUrl}api/reports/exportppe?inspectionId=${inspectionId}`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${token}`
      }
    });

    if (response.status == 200) {
      var data = await response.json();
      if (base64) return { data: data.base64, filename: data.filename }
      let blob = this.convertBase64toBlob(data.base64, 'application/pdf');
      return { data: blob, filename: data.filename }
    }
  }

  public downloadMarine = async (inspectionId, base64: boolean = false) => {
    var token = store.getState().user.token;
    const response = await fetch(`${baseUrl}api/reports/exportmarine?inspectionId=${inspectionId}`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${token}`
      }
    });

    if (response.status == 200) {
      var data = await response.json();
      if (base64) return { data: data.base64, filename: data.filename }
      let blob = this.convertBase64toBlob(data.base64, 'application/pdf');
      return { data: blob, filename: data.filename }
    }
  }

  public downloadMachine = async (inspectionId, base64: boolean = false) => {
    var token = store.getState().user.token;
    const response = await fetch(`${baseUrl}api/reports/exportmachine?inspectionId=${inspectionId}`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${token}`
      }
    });

    if (response.status == 200) {
      var data = await response.json();
      if (base64) return { data: data.base64, filename: data.filename }
      let blob = this.convertBase64toBlob(data.base64, 'application/pdf');
      return { data: blob, filename: data.filename }
    }
  }

  public downloadService = async (inspectionId, base64: boolean = false) => {
    var token = store.getState().user.token;
    const response = await fetch(`${baseUrl}api/reports/exportservice?inspectionId=${inspectionId}`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${token}`
      }
    });

    if (response.status == 200) {
      var data = await response.json();
      if (base64) return { data: data.base64, filename: data.filename }
      let blob = this.convertBase64toBlob(data.base64, 'application/pdf');
      return { data: blob, filename: data.filename }
    }
  }

  public downloadInstallation = async (inspectionId, base64: boolean = false) => {
    var token = store.getState().user.token;
    const response = await fetch(`${baseUrl}api/reports/exportinstallation?inspectionId=${inspectionId}`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${token}`
      }
    });

    if (response.status == 200) {
      var data = await response.json();
      if (base64) return { data: data.base64, filename: data.filename }
      let blob = this.convertBase64toBlob(data.base64, 'application/pdf');
      return { data: blob, filename: data.filename }
    }
  }


  private convertBase64toBlob = (content, contentType) => {
    contentType = contentType || '';
    let sliceSize = 512;
    let byteCharacters = window.atob(content); //method which converts base64 to binary
    let byteArrays = [
    ];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);
      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    let blob = new Blob(byteArrays, {
      type: contentType
    }); //statement which creates the blob
    return blob;
  }

  private buildQueryString = (params: Record<string, any>): string => {
    const queryString = Object.entries(params)
      .filter(([key, value]) => value !== undefined && value !== null)
      .map(([key, value]) => `${key}=${value instanceof Date ? value.toISOString() : value}`)
      .join('&');

    return queryString ? `?${queryString}` : '';
  }
}
