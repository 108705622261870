import * as SQLite from 'expo-sqlite';
import { Database } from './database';
import uuid from 'react-native-uuid';
import { inspectionApi } from '../api/inspection-api';

export class InspectionDatabase extends Database {
  TABLE_NAME = "Inspections";
  TABLE_NAME_IMAGES = "InspectionImages";
  TABLE_NAME_ANSWERED_QUESTIONS = "InspectionAnsweredQuestions";

  initialise(): Promise<void> {
    return new Promise((resolve, reject) => {
      var sql = `CREATE TABLE IF NOT EXISTS Inspections ( 
        id TEXT PRIMARY KEY,
        inspectionDate TEXT,
        nextInspectionDate TEXT,

        firstExamination INT,
        installedCorrectly INT,

        defects TEXT,
        immediateToPersons INT,
        becomeDangerToPersons INT,
        repairDate TEXT,
        repair TEXT,

        identification TEXT,
        particulars TEXT,
        safeForUse INT,

        equipmentId TEXT,
        engineerId TEXT,
        jobId TEXT,
        purposeId TEXT,
        colourId TEXT,
        missing INT,
        nextTestDate TEXT,
        toBeSynced INT
      )`;

      var sqlImages = `CREATE TABLE IF NOT EXISTS InspectionImages (
        id TEXT PRIMARY KEY,
        inspectionId TEXT,
        base64 TEXT
      )`;

      var sqlAnsweredQuestions = `CREATE TABLE IF NOT EXISTS InspectionAnsweredQuestions ( 
        id TEXT PRIMARY KEY,
        questionId TEXT,
        response INT,
        comments TEXT,
        inspectionId TEXT
      )`;

      Database.getDb().transaction((tx) => {
        tx.executeSql(sql);
        tx.executeSql(sqlImages);
        tx.executeSql(sqlAnsweredQuestions);

        var checkColumnSql = "PRAGMA table_info(Inspections)";
        tx.executeSql(checkColumnSql, [], (_, { rows: { _array } }) => {
          if (!doesColumnExist(_array, "nextTestDate")) {
            const alterSql = `ALTER TABLE Inspections ADD COLUMN nextTestDate TEXT`;
            tx.executeSql(alterSql);
          }

          if (!doesColumnExist(_array, "toBeSynced")) {
            const alterSql = `ALTER TABLE Inspections ADD COLUMN toBeSynced INT`;
            tx.executeSql(alterSql);
          }
        });
      }, (error) => {
        console.log(error)
        reject();
      }, () => {
        resolve();
      })
    });
  }

  addMissingInspection(inspectionDate, equipmentId, engineerId, jobId, purposeId, colourId): Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `
          INSERT OR REPLACE INTO ${this.TABLE_NAME} (
            id, inspectionDate, nextInspectionDate, safeForUse, equipmentId, engineerId, jobId, purposeId, colourId, missing, toBeSynced
          )
          VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, 1, 1)
        `

        var inspectionId = uuid.v4();
        tx.executeSql(sql, [
          inspectionId, inspectionDate, inspectionDate, false, equipmentId, engineerId, jobId, purposeId, colourId
        ]);

        sql = "UPDATE JobAssets SET completed = 1, toBeSynced = 1, isMissing = 1 WHERE jobId = ? and equipmentId = ?";
        tx.executeSql(sql, [jobId, equipmentId]);
      }, (error) => {
        console.log("ERROR");
        console.log(error);
        reject();
      }, () => {
        console.log("SUCCESS");
        resolve();
      })
    });
  }

  addInspection(inspectionDate: any, nextInspectionDate: any, firstExamination: any, installedCorrectly: any, defects: any,
    immediateToPersons: any, becomeDangerToPersons: any, repairDate: any, repair: any, identification: any, particulars: any,
    safeForUse: any, equipmentId: any, engineerId: any, jobId: any, purposeId: any, colourId: any, missing: any, images: any,
    questions: any, nextTestDate: any): Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `
          INSERT OR REPLACE INTO ${this.TABLE_NAME} (
            id, inspectionDate, nextInspectionDate, firstExamination, installedCorrectly, defects, immediateToPersons, becomeDangerToPersons,
            repairDate, repair, identification, particulars, safeForUse, equipmentId, engineerId, jobId,
            purposeId, colourId, missing, nextTestDate, toBeSynced
          )
          VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, 1)
        `

        var inspectionId = uuid.v4();

        tx.executeSql(sql, [
          inspectionId, inspectionDate, nextInspectionDate, firstExamination, installedCorrectly, defects, immediateToPersons, becomeDangerToPersons,
          repairDate, repair, identification, particulars, safeForUse, equipmentId, engineerId, jobId,
          purposeId, colourId, missing, nextTestDate
        ]);
        for (let question of questions) {
          var sql = `
              INSERT OR REPLACE INTO ${this.TABLE_NAME_ANSWERED_QUESTIONS} (
                id, questionId, response, comments, inspectionId
              )
              VALUES (?, ?, ?, ?, ?)
            `;

          tx.executeSql(sql, [uuid.v4(), question.questionId, question.response, question.comments, inspectionId]);
        }

        if (images.length > 0) {
          sql = `INSERT INTO ${this.TABLE_NAME_IMAGES} (id, inspectionId, base64) VALUES (?, ?, ?)`;
          for (let image of images) {
            tx.executeSql(sql, [uuid.v4(), inspectionId, image]);
          }
        }

        //Update this asset to be completed 
        sql = "UPDATE JobAssets SET completed = 1, toBeSynced = 1 WHERE jobId = ? and equipmentId = ?";
        tx.executeSql(sql, [jobId, equipmentId]);

        console.log("UPDATED JOB Assets - JobId: " + jobId + " EquipId: " + equipmentId);
      }, (error) => {
        console.log("ERROR");
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  updateInspection(inspectionId: any, inspectionDate: any, nextInspectionDate: any, firstExamination: any, installedCorrectly: any, defects: any,
    immediateToPersons: any, becomeDangerToPersons: any, repairDate: any, repair: any, identification: any, particulars: any,
    safeForUse: any, equipmentId: any, engineerId: any, jobId: any, purposeId: any, colourId: any, missing: any, images: any,
    questions: any, nextTestDate: any): Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        tx.executeSql(`DELETE FROM ${this.TABLE_NAME_ANSWERED_QUESTIONS} WHERE inspectionId = ?`, [inspectionId]);
        tx.executeSql(`DELETE FROM ${this.TABLE_NAME_IMAGES} WHERE inspectionId = ?`, [inspectionId]);

        var sql = `
          UPDATE ${this.TABLE_NAME}
          SET
            inspectionDate = ?, nextInspectionDate = ?, firstExamination = ?, installedCorrectly = ?, defects = ?, immediateToPersons = ?, becomeDangerToPersons = ?,
            repairDate = ?, repair = ?, identification = ?, particulars = ?, safeForUse = ?, equipmentId = ?, engineerId = ?, jobId = ?,
            purposeId = ?, colourId = ?, missing = ?, nextTestDate = ?, toBeSynced = 1
          WHERE id = ?
        `;

        tx.executeSql(sql, [
          inspectionDate, nextInspectionDate, firstExamination, installedCorrectly, defects, immediateToPersons, becomeDangerToPersons,
          repairDate, repair, identification, particulars, safeForUse, equipmentId, engineerId, jobId,
          purposeId, colourId, missing, nextTestDate, inspectionId
        ]);

        for (let question of questions) {
          var sql = `
              INSERT OR REPLACE INTO ${this.TABLE_NAME_ANSWERED_QUESTIONS} (
                id, questionId, response, comments, inspectionId
              )
              VALUES (?, ?, ?, ?, ?)
            `;

          tx.executeSql(sql, [uuid.v4(), question.questionId, question.response, question.comments, inspectionId]);
        }

        if (images.length > 0) {
          sql = `INSERT INTO ${this.TABLE_NAME_IMAGES} (id, inspectionId, base64) VALUES (?, ?, ?)`;
          for (let image of images) {
            tx.executeSql(sql, [uuid.v4(), inspectionId, image]);
          }
        }

        // Update this asset to be completed 
        sql = "UPDATE JobAssets SET completed = 1, toBeSynced = 1 WHERE jobId = ? and equipmentId = ?";
        tx.executeSql(sql, [jobId, equipmentId]);

        console.log("UPDATED JOB Assets - JobId: " + jobId + " EquipId: " + equipmentId);
      }, (error) => {
        console.log("ERROR");
        console.log(error);
        reject();
      }, () => {
        resolve();
      });
    });
  }


  deleteInspection(inspectionId): Promise<boolean> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `DELETE FROM Inspections WHERE id = ?`;
        tx.executeSql(sql, [inspectionId], (_, { rows: { _array } }) => { });

        sql = `DELETE FROM InspectionImages WHERE inspectionId = ?`;
        tx.executeSql(sql, [inspectionId], (_, { rows: { _array } }) => { });

        sql = `DELETE FROM ${this.TABLE_NAME_ANSWERED_QUESTIONS} WHERE inspectionId = ?`;
        tx.executeSql(sql, [inspectionId], (_, { rows: { _array } }) => { });

      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(true);
      })
    });
  }

  getForSync(): Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM Inspections WHERE toBeSynced = 1
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          data = _array;

          var sqlQuestions = `SELECT * FROM ${this.TABLE_NAME_ANSWERED_QUESTIONS} WHERE inspectionId = ?`;

          for (let inspection of data) {
            sql = `SELECT base64 FROM ${this.TABLE_NAME_IMAGES} WHERE inspectionId = ?`;
            tx.executeSql(sql, [inspection.id], (_, { rows: { _array } }) => {
              inspection.images = [];
              for (let image of _array) {
                inspection.images.push(image.base64)
              }
            });
            tx.executeSql(sqlQuestions, [inspection.id], (_, { rows: { _array } }) => {
              inspection.questions = _array;
            });
          }
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  getCompletedInspection(jobId, equipmentId): Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM Inspections
          WHERE jobId = ? AND equipmentId = ?
        `;

        tx.executeSql(sql, [jobId, equipmentId], (_, { rows: { _array } }) => {
          data = _array;

          var sqlQuestions = `SELECT * FROM ${this.TABLE_NAME_ANSWERED_QUESTIONS} WHERE inspectionId = ?`;

          for (let inspection of data) {
            sql = `SELECT base64 FROM ${this.TABLE_NAME_IMAGES} WHERE inspectionId = ?`;
            tx.executeSql(sql, [inspection.id], (_, { rows: { _array } }) => {
              inspection.images = [];
              for (let image of _array) {
                inspection.images.push(image.base64)
              }
            });
            tx.executeSql(sqlQuestions, [inspection.id], (_, { rows: { _array } }) => {
              inspection.questions = _array;
            });
          }
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  getByJobId(jobId: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        tx.executeSql(
          `SELECT * FROM Inspections WHERE jobId = ? AND toBeSynced = 0`,
          [jobId],
          (_, { rows: { _array } }) => resolve(_array),
          (_, error) => reject(error)
        );
      });
    });
  }

  updateInspectionToBeSynced(inspectionId: string, toBeSynced: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        tx.executeSql(
          `UPDATE Inspections SET toBeSynced = ? WHERE id = ?`,
          [toBeSynced ? 1 : 0, inspectionId],
          () => resolve(),
          (_, error) => reject(error)
        );
      });
    });
  }

  getInspectionImages(inspectionId: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        tx.executeSql(
          `SELECT * FROM ${this.TABLE_NAME_IMAGES} WHERE inspectionId = ?`,
          [inspectionId],
          (_, { rows: { _array } }) => resolve(_array),
          (_, error) => reject(error)
        );
      });
    });
  }
}

function doesColumnExist(array: any, columnName: string) {
  return array.some((column) => column.name === columnName);
}
