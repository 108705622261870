import * as React from 'react';
import { useState } from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, ScrollView } from 'react-native';
import { DataTable } from 'react-native-paper';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
import { useIsFocused } from "@react-navigation/native";
import { Entypo, Feather, SimpleLineIcons, Ionicons } from '@expo/vector-icons';
import { PagedTableDto } from '@store/services/api/base-api';
import { categoryApi, useCreateCategoryFieldMutation, useDeleteCategoryFieldMutation, useGetAllMasterCategoriesQuery, useGetCategoriesQuery, useUpdateCategoryFieldMutation } from '@store/services/api/category-api';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { Picker } from '@react-native-picker/picker';
import { Controller, useForm } from 'react-hook-form';
import { DataTableHeader } from '@web/components/table/DatatableHeader';
import PaginationComponent from '@web/components/Pagination';
import { FIRST_PAGE, MAX_ITEMS_BEFORE_ALL } from '../../../../../../constants/Constants';
import Spinner from 'react-native-loading-spinner-overlay';

export default function CategoriesListScreen({ navigation }: { navigation: any }) {
  const isFocused = useIsFocused()

  const [page, setPage] = React.useState<number>(0);
  const [sortedColumn, setSortedColumn] = React.useState<string>("name");
  const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(true);
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState<any>(10);
  const [loading, setLoading] = useState<any>(false);

  const { data: masterCategories } = useGetAllMasterCategoriesQuery(null);

  // Filters 
  const [categoryName, setCategoryName] = useState("");
  const [masterCategoryId, setMasterCategoryId] = useState("");

  const [trigger, categoriesResult] = categoryApi.useLazyGetCategoriesQuery();

  const [selectedCategory, setSelectedCategory] = React.useState<any>(null);

  const loadTable = () => {
    const pageNumber = numberOfItemsPerPage > MAX_ITEMS_BEFORE_ALL ? FIRST_PAGE : page;
    var dto = new PagedTableDto(pageNumber, {
      name: categoryName,
      masterCategoryId: masterCategoryId,
      pageSize: numberOfItemsPerPage
    }, sortedColumn, sortedColumnAscending);
    trigger(dto).then(() => {
      setLoading(false);
    });
  }

  const resetFilters = () => {
    setCategoryName("");
    setMasterCategoryId("");
  }

  function applyFilters() {
    setPage((curr) => {
      if (curr == 0) loadTable();
      return 0;
    })
  }

  React.useEffect(() => {
    if (isFocused) {
      loadTable();
    }
  }, [page, isFocused, sortedColumn, sortedColumnAscending, numberOfItemsPerPage]);

  if (categoriesResult.isLoading) {
    return <LoadingIndicator />
  }

  return (
    <ScrollView style={styles.container}>
      <Spinner
        visible={loading}
        textContent={'Loading...'}
        textStyle={GlobalStyle.spinnerTextStyle}
      />
      <View style={GlobalStyle.filters}>
        <View style={GlobalStyle.filters__header}>
          <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
        </View>

        <View style={GlobalStyle.filter__columns}>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Category Name</Text>
            <TextInput
              style={[GlobalStyle.column, GlobalStyle.column__input]}
              placeholder="Category Name"
              onChangeText={setCategoryName}
              value={categoryName}
            />
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Master Category</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]}
              selectedValue={masterCategoryId}
              onValueChange={(itemValue, itemIndex) => setMasterCategoryId(itemValue)}
              itemStyle={{ height: 50 }}>

              <Picker.Item label="All Master Categories" value="" />

              {masterCategories != null && masterCategories.map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={applyFilters}>
                <SimpleLineIcons name="magnifier" size={16} color="white" />
              </TouchableOpacity>

              <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={() => resetFilters()}>
                <SimpleLineIcons name="ban" size={16} color="black" />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>

      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <TouchableOpacity style={GlobalStyle.add__button} onPress={() => navigation.navigate("manage")}>
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingLeft: 5, paddingRight: 10 }}>
              <Ionicons name="add" size={24} color="white" /><Text style={GlobalStyle.form__submit__button__text}>Add New Category</Text>
            </View>
          </TouchableOpacity>

          <DataTable style={GlobalStyle.table}>
            <DataTableHeader
              sortedColumn={sortedColumn}
              sortedColumnAscending={sortedColumnAscending}
              onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
              headers={[
                { text: 'Name', sortedName: "name", visible: true },
                { text: 'Inspection Interval', sortedName: "inspectionInterval", visible: true },
                { text: 'Master Category', sortedName: "masterCategoryName", visible: true },
                { text: 'Checklist', sortedName: "isServiceable", visible: true },
                { text: 'Option', visible: true },
                { text: 'Fields', visible: true },
              ]}
              style={styles.tableHeader}
            />

            {categoriesResult?.data?.list.map((element: any) => {
              return (
                <DataTable.Row key={element.id}>
                  <DataTable.Cell style={styles.tableCell}>{element.name}</DataTable.Cell>
                  <DataTable.Cell style={styles.tableCell}>{element.inspectionInterval}</DataTable.Cell>
                  <DataTable.Cell style={styles.tableCell}>{element.masterCategoryName}</DataTable.Cell>
                  {element.isServiceable ? (
                    <DataTable.Cell style={styles.tableCell}>
                      <TouchableOpacity
                        onPress={() =>
                          navigation.navigate("questions", { categoryId: element.id, category: element })}
                      >
                        <Text>Checklist</Text>
                      </TouchableOpacity>
                    </DataTable.Cell>
                  ) : (
                    <DataTable.Cell style={styles.emptyCell}>         </DataTable.Cell>
                  )}
                  <DataTable.Cell style={styles.tableCell}>
                    <TouchableOpacity onPress={() => navigation.navigate("manage", { categoryId: element.id })}>
                      <Entypo name="pencil" size={24} color="black" />
                    </TouchableOpacity>
                  </DataTable.Cell>
                  <DataTable.Cell style={styles.tableCell}>
                    <TouchableOpacity onPress={() => setSelectedCategory(element)}>
                      <Entypo name="cog" size={24} color="black" />
                    </TouchableOpacity>
                  </DataTable.Cell>
                </DataTable.Row>
              );
            })}
          </DataTable>



        </View>

        {selectedCategory != null &&
          <View style={{ width: 450, marginLeft: 20 }}>
            <CategoryFieldsList category={selectedCategory} onClear={() => setSelectedCategory(null)} />
          </View>
        }
      </View>
      <PaginationComponent
        numberOfItemsPerPage={numberOfItemsPerPage}
        setNumberOfItemsPerPage={setNumberOfItemsPerPage}
        page={page}
        setPage={setPage}
        fetchResult={categoriesResult}
        loading={loading}
        setLoading={setLoading}
      />
    </ScrollView>
  );
}

export function CategoryFieldsList({ category, onClear }) {
  const [page, setPage] = useState<number>(0);
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState<any>(10);
  const [loading, setLoading] = useState<any>(false);

  const [trigger, categoryFieldsResult] = categoryApi.useLazyGetCategoryFieldsQuery();
  const [createCategoryField, createCategoryFieldResult] = useCreateCategoryFieldMutation();
  const [updateCategoryField, updateCategoryFieldResult] = useUpdateCategoryFieldMutation();
  const [deleteCategoryField, deleteCategoryFieldResult] = useDeleteCategoryFieldMutation();

  const { control, handleSubmit, getValues, setValue, reset, watch, formState: { errors } } = useForm({
    defaultValues: {
      id: null,
      categoryId: category?.id,
      fieldName: "",
    }
  });

  const id = watch("id");

  React.useEffect(() => {
    loadTable();
  }, [page, location, numberOfItemsPerPage]);

  const loadTable = () => {
    const pageNumber = numberOfItemsPerPage > MAX_ITEMS_BEFORE_ALL ? FIRST_PAGE : page;
    trigger(new PagedTableDto(pageNumber, {
      categoryId: category?.id,
      pageSize: numberOfItemsPerPage
    })).then(() => {
      setLoading(false);
    });
  }

  const onSubmit = async (data) => {
    data.categoryId = category.id;
    var result = null;
    if (data.id != null) {
      result = await updateCategoryField(data) as any;
    } else {
      result = await createCategoryField(data) as any;
    }

    if (result.error) {
      alert(result.error)
      return;
    }

    reset();
    loadTable();
  }

  const edit = (categoryField) => {
    setValue("id", categoryField.id);
    setValue("categoryId", categoryField.categoryId);
    setValue("fieldName", categoryField.fieldName);
  }

  const remove = async (category) => {
    if (confirm("Are you sure you want to delete this field?")) {
      var result = await deleteCategoryField(category.id) as any;
      if (result.error) {
        alert(result.error)
        return;
      }

      loadTable();
    }
  }

  return (
    <View>
      <Spinner
        visible={loading}
        textContent={'Loading...'}
        textStyle={GlobalStyle.spinnerTextStyle}
      />
      <TouchableOpacity onPress={() => onClear()}>
        <Text style={GlobalStyle.add__new__link}>
          <Entypo name="chevron-left" size={12} color="black" style={{ marginRight: 10 }} />
          Fields
        </Text>
      </TouchableOpacity>

      <View style={[GlobalStyle.form, styles.form]}>
        <View style={GlobalStyle.form__row}>
          <View style={[GlobalStyle.form__column, GlobalStyle.form__column__full]}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="fieldName"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Field (*)</Text>
                  <TextInput
                    style={GlobalStyle.form__column__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.fieldName && <Text style={GlobalStyle.form__input__validation}>Field is required.</Text>}
          </View>

          <View style={styles.form__buttons}>
            <TouchableOpacity style={styles.form__add__btn} onPress={handleSubmit(onSubmit)}>
              <Text style={styles.form__add__btn__text}>{id == null ? 'Add' : 'Update'}</Text>
            </TouchableOpacity>

            {id != null &&
              <TouchableOpacity style={styles.form__add__btn} onPress={() => reset()}>
                <Text style={styles.form__add__btn__text}>Clear</Text>
              </TouchableOpacity>
            }
          </View>
        </View>
      </View>

      <DataTable style={GlobalStyle.table}>
        <DataTable.Header>
          <DataTable.Title>Field</DataTable.Title>
          <DataTable.Title>Actions</DataTable.Title>
        </DataTable.Header>

        {categoryFieldsResult?.data?.list.map((element: any) => {
          return (
            <DataTable.Row key={element.id}>
              <DataTable.Cell>{element.fieldName}</DataTable.Cell>
              <DataTable.Cell>
                <View style={styles.table__actions}>
                  <TouchableOpacity style={styles.action} onPress={() => edit(element)}><Feather name="edit" size={20} color="black" /></TouchableOpacity>
                  <TouchableOpacity style={styles.action} onPress={() => remove(element)}><Feather name="trash" size={20} color="black" /></TouchableOpacity>
                </View>
              </DataTable.Cell>
            </DataTable.Row>
          )
        })}
      </DataTable>

      <PaginationComponent
        numberOfItemsPerPage={numberOfItemsPerPage}
        setNumberOfItemsPerPage={setNumberOfItemsPerPage}
        page={page}
        setPage={setPage}
        fetchResult={categoryFieldsResult}
        loading={loading}
        setLoading={setLoading}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },

  form: {
    borderBottomWidth: 1,
    borderColor: '#CECECE',
    paddingBottom: 20,
    marginBottom: 10
  },

  form__buttons: {
    width: 150,
    flexDirection: 'row',
  },

  form__add__btn: {
    backgroundColor: PRIMARY_COLOUR,
    flex: 1,
    height: 31,
    alignSelf: 'flex-end',
    textAlign: 'center',
    marginLeft: 10,
    marginBottom: 5,

    alignItems: 'center',
    justifyContent: 'center',
  },

  form__add__btn__text: {
    color: '#FFF',
  },

  table__actions: {
    flexDirection: 'row',
  },

  action: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  tableHeader: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableCell: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  emptyCell: {
    flex: 1,
  },


});
