import * as React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import { useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { Dimensions } from 'react-native';

const isTabletScreen = Dimensions.get("screen").width < 960;
const isMobileScreen = Dimensions.get("screen").width < 480;

export function JobGridStats({ jobs }) {
  const modules = useSelector((state: RootState) => state.user.modules);

  return (
    <View style={styles.job__grid}>
      {modules.isRoteEnabled == true &&
        <View style={styles.job__block}>
          <View style={styles.job__icon}>
            <Ionicons name="checkmark-circle-outline" size={isMobileScreen ? 10 : (isTabletScreen ? 16 : 32)} color="#2B93F1" />
          </View>

          <View style={styles.job__description__block}>
            <Text style={styles.job__text}>ROTE</Text>
            <Text style={styles.job__text__subtitle}>{isTabletScreen ? '' : '# Jobs: '} <Text style={{ fontFamily: 'OpenSans-Bold' }}>{jobs.filter(j => j.jobType == 0).length}</Text></Text>
          </View>
        </View>
      }
      {modules.isServiceEnabled == true &&
        <View style={styles.job__block}>
          <View style={[styles.job__icon, styles.servicing__icon]}>
            <Ionicons name="cog-outline" size={isMobileScreen ? 8 : (isTabletScreen ? 16 : 32)} color="#F7A931" />
          </View>

          <View style={styles.job__description__block}>
            <Text style={styles.job__text}>SERVICING</Text>
            <Text style={styles.job__text__subtitle}>{isTabletScreen ? '' : '# Jobs: '} <Text style={{ fontFamily: 'OpenSans-Bold' }}>{jobs.filter(j => j.jobType == 1).length}</Text></Text>
          </View>
        </View>
      }
      {modules.isBreakdownEnabled == true &&
        <View style={styles.job__block}>
          <View style={[styles.job__icon, styles.breakdown__icon]}>
            <Ionicons name="warning-outline" size={isMobileScreen ? 8 : (isTabletScreen ? 16 : 32)} color="#FF4C4F" />
          </View>

          <View style={styles.job__description__block}>
            <Text style={styles.job__text}>BREAKDOWN</Text>
            <Text style={styles.job__text__subtitle}>{isTabletScreen ? '' : '# Jobs: '} <Text style={{ fontFamily: 'OpenSans-Bold' }}>{jobs.filter(j => j.jobType == 2).length}</Text></Text>
          </View>
        </View>
      }
      {modules.isProofLoadEnabled == true &&
        <View style={styles.job__block}>
          <View style={[styles.job__icon, styles.proofload__icon]}>
            <Ionicons name="ios-reload-circle-outline" size={isMobileScreen ? 8 : (isTabletScreen ? 16 : 32)} color="#0D4487" />
          </View>

          <View style={styles.job__description__block}>
            <Text style={styles.job__text}>PROOF LOAD</Text>
            <Text style={styles.job__text__subtitle}>{isTabletScreen ? '' : '# Jobs: '} <Text style={{ fontFamily: 'OpenSans-Bold' }}>{jobs.filter(j => j.jobType == 3).length}</Text></Text>
          </View>
        </View>
      }
      {modules.isInstallationEnabled == true &&
        <View style={styles.job__block}>
          <View style={[styles.job__icon, styles.installation_icon]}>
            {/* <Ionicons name="ios-reload-circle-outline" size={isMobileScreen ? 8 : (isTabletScreen ? 16 : 32)} color="#0D4487" /> */}
            <MaterialCommunityIcons name="tools" size={isMobileScreen ? 8 : (isTabletScreen ? 16 : 32)} color="#b565c4" />
          </View>

          <View style={styles.job__description__block}>
            <Text style={styles.job__text}>INSTALLATION</Text>
            <Text style={styles.job__text__subtitle}>{isTabletScreen ? '' : '# Jobs: '} <Text style={{ fontFamily: 'OpenSans-Bold' }}>{jobs.filter(j => j.jobType == 4).length}</Text></Text>
          </View>
        </View>
      }
    </View>
  )
}

const styles = StyleSheet.create({
  job__grid: {
    flexDirection: 'row',
    flexWrap: isMobileScreen ? "wrap" : "nowrap"
  },

  job__block: {
    flex: isMobileScreen ? 0 : 1,
    flexBasis: isMobileScreen ? "100%" : 0,
    backgroundColor: '#FFF',
    margin: isTabletScreen ? 5 : 10,
    marginBottom: 0,
    marginLeft: 0,
    minHeight: isMobileScreen ? 25 : 50,
    padding: isMobileScreen ? 1 : (isTabletScreen ? 5 : 15),
    alignItems: 'center',

    flexDirection: isTabletScreen ? 'column' : 'row',
  },

  job__icon: {
    padding: isMobileScreen ? 3 : 10,
    backgroundColor: '#E9F5FE',
    borderRadius: 10,

    alignSelf: isTabletScreen || isMobileScreen ? 'center' : 'flex-start',
    marginBottom: isMobileScreen ? 2 : (isTabletScreen ? 5 : 0),
    marginRight: isTabletScreen ? 0 : 10,
  },

  job__description__block: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flex: isTabletScreen || isMobileScreen ? 0 : 1
  },

  job__text: {
    display: 'flex',
    fontFamily: 'OpenSans-Bold',
    fontSize: isMobileScreen ? 10 : (isTabletScreen ? 14 : 22),
    textAlign: 'center',
    alignSelf: 'center'
  },

  job__text__subtitle: {
    fontFamily: 'OpenSans-Light',
    fontSize: isMobileScreen ? 10 : (isTabletScreen ? 14 : 22),
    textAlign: 'center',
    alignSelf: 'center',
  },

  servicing__icon: {
    backgroundColor: '#FFF6E8'
  },

  breakdown__icon: {
    backgroundColor: '#FFE0E0',
  },

  proofload__icon: {
    backgroundColor: '#E7ECF3'
  },

  installation_icon: {
    backgroundColor: '#ddd3ee'
  }
});
