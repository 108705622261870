import * as React from 'react';
import { useState, useEffect } from 'react';
import { useGetAllCompaniesQuery } from '@store/services/api/company-api';
import { useGetAllLocationsQuery, useGetAllSubLocationsQuery } from '@store/services/api/location-api';
import { View, Text, StyleSheet, TouchableOpacity, ScrollView } from 'react-native';
import GlobalStyle from '../../../../../constants/GlobalStyle';
import { useGetEngineersQuery } from '@store/services/api/user-api';
import moment from 'moment';
import { useGetAllCategoriesQuery, useGetAllMasterCategoriesQuery } from '@store/services/api/category-api';
import { Foundation, Feather } from '@expo/vector-icons';
import { inspectionApi } from '@store/services/api/inspection-api';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { PagedTableDto } from '@store/services/api/base-api';
import { DataTable } from 'react-native-paper';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@store/index';
import { ReportableService } from '@store/services/reportable/reportable.service';
import { Basket, BasketIcon } from '@web/components/Basket';
import { ImageViewer } from '@web/components/ImageViewer';
import { ReportKey } from '@web/components/ReportKey';
import Spinner from 'react-native-loading-spinner-overlay';
import { EquipmentFiles } from '../Management/Equipment/EquipmentFiles';
import { DataTableHeader } from '@web/components/table/DatatableHeader';
import PaginationComponent from '@web/components/Pagination';
import { MAX_ITEMS_BEFORE_ALL, FIRST_PAGE } from '../../../../../constants/Constants';
import { addMultipleBasketInspections, removeMultipleBasketInspections } from '@store/slices/basket-slice';
import { invertBoolString } from '../../../../../helpers/invert-string';
import { SearchFilters, CompanyIdFilter, LocationFilter, DateFilter, EngineersFilter, CategoryFilter, SerialFilter, JobNumberFilter, SubLocationFilter, StatusFilter, ReferenceFilter, SearchFilterRow, ScrappedStatusFilter, SearchFilterButtons, AssetFilter } from '@web/components/search-filters/SearchFilters';
import { InspectionDateRangeFilter, NextTestDateRangeFilter } from '@web/components/search-filters/common-search-filters';

export default function InspectionScreen({ navigation }: { navigation: any }) {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  const [loading, setLoading] = useState<any>(false);
  const [onBasketClose, setOnBasketClose] = useState<boolean>(false);
  const { data: companies } = useGetAllCompaniesQuery(null);
  const { data: locations } = useGetAllLocationsQuery(null);
  const { data: subLocations } = useGetAllSubLocationsQuery(null);
  const { data: engineers } = useGetEngineersQuery(null);
  const { data: categories } = useGetAllCategoriesQuery(null);
  const { data: masterCategories } = useGetAllMasterCategoriesQuery(null);
  const selectedInspections = useSelector((state: RootState) => state.basket.inspections);
  const [page, setPage] = React.useState<number>(0);
  const [trigger, inspectionResult] = inspectionApi.useLazyGetInspectionsQuery();
  const [sortedColumn, setSortedColumn] = React.useState<string>("inspectionDate");
  const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(false);
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState<any>(10);

  const [companyId, setCompanyId] = useState(user.companyId);
  const [locationId, setLocationId] = useState("");
  const [subLocationId, setSubLocationId] = useState("");
  const [engineerId, setEngineerId] = useState("");
  const [dateRange, setDateRange] = useState<any>("");
  const [startDate, setStartDate] = useState(moment().add(-6, 'months').toDate());
  const [endDate, setEndDate] = useState(moment().toDate());
  const [testDateRange, setTestDateRange] = useState<any>("");
  const [testStartDate, setTestStartDate] = useState<any>(moment().toDate());
  const [testEndDate, setTestEndDate] = useState<any>(moment().add(6, 'months').toDate());
  const [serialNumber, setSerialNumber] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [jobNumber, setJobNumber] = useState("");
  const [yourReference, setYourReference] = useState("");
  const [reportNumber, setReportNumber] = useState("");
  const [statusId, setStatusId] = useState("-1");
  const [scrappedStatus, setScrappedStatus] = useState("-1");
  const [assetNumber, setAssetNumber] = useState("");

  const [imagesToView, setImagesToView] = useState([]);

  const isCategoryMarine = () => {
    const selectedCategory = categories?.find(c => c.id == categoryId);
    const marineCategoryId = masterCategories?.find(m => m.name == "Marine 2006")?.id;
    if (!selectedCategory || !marineCategoryId) return false;
    return selectedCategory.masterCategoryId == marineCategoryId;
  }

  const updateDateRange = (val) => {
    setDateRange(val);

    let startDate = moment();
    const endDate = moment();
    if (val == "-1") {
      startDate = moment().add(-1, 'week');
    } else {
      startDate = moment().add(-Number.parseInt(val), 'M');
    }

    setStartDate(startDate.toDate());
    setEndDate(endDate.toDate());
  }

  const updateNextTestDateRange = (val) => {
    setTestDateRange(val);

    const startDate = moment();
    let endDate = moment();
    if (val == "-1") {
      endDate = moment().add(1, 'week');
    } else {
      endDate = moment().add(Number.parseInt(val), 'M');
    }

    setTestStartDate(startDate.toDate());
    setTestEndDate(endDate.toDate());
  }

  const resetFilters = () => {
    setCompanyId(user.companyId ?? "");
    setLocationId("");
    setSubLocationId("");
    setEngineerId("");
    setDateRange("");
    setStartDate(moment().add(-6, 'months').toDate());
    setEndDate(moment().toDate());
    setTestDateRange("");
    setTestStartDate(moment().toDate());
    setTestEndDate(moment().add(6, 'months').toDate());
    setSerialNumber("");
    setCategoryId("");
    setJobNumber("");
    setReportNumber("");
    setYourReference("");
    setStatusId("-1");
    setScrappedStatus("-1");
    setAssetNumber("");
  }

  const loadTable = () => {
    const pageNumber = numberOfItemsPerPage > MAX_ITEMS_BEFORE_ALL ? FIRST_PAGE : page;
    let filterValues: any = {
      companyId: companyId,
      locationId: locationId,
      engineerId: engineerId,
      startDate: moment(startDate).toISOString(),
      endDate: moment(endDate).toISOString(),
      serialNumber: serialNumber,
      categoryId: categoryId,
      jobNumber: jobNumber,
      yourReference: yourReference,
      reportNumber: reportNumber,
      statusId: statusId,
      subLocationId: subLocationId,
      scrappedStatus: scrappedStatus,
      pageSize: numberOfItemsPerPage,
      assetNumber: assetNumber
    }

    if (isCategoryMarine()) {
      filterValues = {
        ...filterValues,
        nextTestStartDate: moment(testStartDate).toISOString(),
        nextTestEndDate: moment(testEndDate).toISOString(),
      }
    }

    var dto = new PagedTableDto(pageNumber, filterValues, sortedColumn, sortedColumnAscending);
    trigger(dto).then(() => {
      setLoading(false);
    });
  }

  const downloadCert = async (inspection) => {
    setLoading(true)
    var reportableService = new ReportableService();
    var data = null;

    if (inspection.masterCategoryName == "PPE")
      data = await reportableService.downloadPPE(inspection.id);
    else if (inspection.masterCategoryName == "Marine 2006") {
      data = await reportableService.downloadMarine(inspection.id);
    }
    else if (inspection.masterCategoryName == "PUWER 1998") {
      data = await reportableService.downloadMachine(inspection.id);
    }
    else
      data = await reportableService.downloadRote(inspection.id);

    if (data != null) {
      const fileSaver = require('file-saver');
      fileSaver(data.data, data.filename);
      setLoading(false)
    }
  }

  const downloadRAMS = async (inspection) => {
    setLoading(true);
    var reportableService = new ReportableService();
    var data = await reportableService.downloadRAMS(inspection.ramsId);
    if (data != null) {
      const fileSaver = require('file-saver');
      fileSaver(data.data, data.filename);
      setLoading(false);
    }
  }

  const downloadChecklist = async (inspection) => {
    setLoading(true)
    var reportableService = new ReportableService();
    var data = await reportableService.downloadChecklist(inspection.id);
    if (data != null) {
      const fileSaver = require('file-saver');
      fileSaver(data.data, data.filename);
      setLoading(false);
    }
  }

  const downloadBatchRoteTable = async () => {
    setLoading(true);
    var reportableService = new ReportableService();
    const params = {
      companyId,
      locationId,
      subLocationId,
      engineerId,
      startDate,
      endDate,
      serialNumber,
      categoryId,
      statusId,
      jobNumber,
      yourReference,
      assetNumber
    };

    const downloadResults = await reportableService.downloadBatchRoteTableReport(params);

    if (downloadResults.length > 0) {
      const fileSaver = require('file-saver');
      for (const result of downloadResults) {
        fileSaver(result.data, result.filename);
      }
      setLoading(false)
    }
  }

  const inspectionsPageList = inspectionResult?.data?.list.filter(i => !i.missing);

  const addAllToBasket = async () => {
    const inspectionsList = (inspectionsPageList && inspectionsPageList.length > 0) ? inspectionsPageList
      .map(({ id, serialNumber, type, masterCategoryName, }) => ({
        id,
        serialNumber,
        type,
        masterCategoryName
      })) : [];

    dispatch(addMultipleBasketInspections(inspectionsList));
  }

  const removeSelectedPageFromBasket = async () => {
    dispatch(removeMultipleBasketInspections((inspectionsPageList && inspectionsPageList.length > 0) ? inspectionsPageList : []))
  }

  const isSelectAllChecked = (inspectionsPageList && inspectionsPageList.length > 0) && inspectionsPageList.every((item) =>
    selectedInspections.some(selectedItem => selectedItem.id === item.id)
  );

  function applyFilters() {
    setPage((curr) => {
      if (curr == 0) loadTable();
      return 0;
    })
  }

  function handleSetCompanyId(val) {
    setLocationId("");
    setSubLocationId("");
    setCompanyId(val);
  }

  function handleSetTestEndDate(val) {
    setTestDateRange("");
    setTestEndDate(val);
  }

  function handleSetCompany(itemValue) {
    setCompanyId(itemValue);
    setLocationId("");
    setSubLocationId("");
  }

  function handleSetStartDate(val) {
    setDateRange("");
    setStartDate(val);
  }

  useEffect(() => {
    loadTable();
  }, [page, sortedColumn, sortedColumnAscending, numberOfItemsPerPage, onBasketClose])

  if (inspectionResult.isLoading) {
    return <LoadingIndicator />
  }

  return (
    <ScrollView>
      <Spinner
        visible={loading}
        textContent={'Loading...'}
        textStyle={GlobalStyle.spinnerTextStyle}
      />
      <View style={styles.container}>
        {imagesToView.length > 0 && <ImageViewer images={imagesToView} onClosed={() => setImagesToView([])} />}
      </View>

      <SearchFilters>
        <CompanyIdFilter value={companyId} onValueChange={handleSetCompanyId} companies={companies} />
        <LocationFilter companyId={companyId} value={locationId} onValueChange={setLocationId} locations={locations} />
        <SubLocationFilter companyId={companyId} locationId={locationId} subLocations={subLocations}
          value={subLocationId} onValueChange={setSubLocationId} />
        <EngineersFilter value={engineerId} onValueChange={setEngineerId} engineers={engineers} />

        <CategoryFilter value={categoryId} onValueChange={setCategoryId} categories={categories} />
        <SerialFilter value={serialNumber} onValueChange={setSerialNumber} />
        <AssetFilter value={assetNumber} onValueChange={setAssetNumber} />
        <JobNumberFilter value={jobNumber} onValueChange={setJobNumber} />
        <StatusFilter value={statusId} onValueChange={setStatusId} />
        <ReferenceFilter value={yourReference} onValueChange={setYourReference} />
        <ScrappedStatusFilter value={scrappedStatus} onValueChange={setScrappedStatus} />

        <SearchFilterRow>
          <InspectionDateRangeFilter value={dateRange} onValueChange={updateDateRange} />
          <DateFilter title="Inspection Start Date" value={startDate} onValueChange={handleSetStartDate} />
          <DateFilter title="Inspection End Date" value={endDate} onValueChange={setEndDate} />
          {!isCategoryMarine() &&
            <SearchFilterButtons onSubmit={applyFilters} onClearFilters={resetFilters} />
          }
        </SearchFilterRow>

        {isCategoryMarine() &&
          <SearchFilterRow>
            <NextTestDateRangeFilter value={testDateRange} onValueChange={updateNextTestDateRange} />
            <DateFilter title="Next Test Start Date" value={testStartDate} onValueChange={setTestStartDate} />
            <DateFilter title="Next Test End Date" value={testEndDate} onValueChange={handleSetTestEndDate} />
            <SearchFilterButtons onSubmit={applyFilters} onClearFilters={resetFilters} />
          </SearchFilterRow>
        }
      </SearchFilters>

      <Basket onBasketClose={onBasketClose} setOnBasketClose={setOnBasketClose} />
      {companyId !== null && <View style={{ justifyContent: 'center' }}>
        <TouchableOpacity style={GlobalStyle.add__button} onPress={() => downloadBatchRoteTable()}>
          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingLeft: 5, paddingRight: 10 }}>
            <Feather name="download" size={24} color="white" /><Text style={GlobalStyle.form__submit__button__text}>Download Batch Report</Text>
          </View>
        </TouchableOpacity>
      </View>}
      <View style={{ flexDirection: 'row' }}>
        <ReportKey showRote={true} showPowra={true} showChecklist={true} />
        <View style={{ justifyContent: 'flex-start', alignItems: 'center', paddingBottom: 10 }}>
        </View>
      </View>

      <DataTable style={GlobalStyle.table}>
        <DataTableHeader
          sortedColumn={sortedColumn}
          sortedColumnAscending={sortedColumnAscending}
          onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
          headers={[
            { text: 'Basket', visible: true },
            { text: 'Report', visible: true },
            { text: 'Documents', visible: true },
            { text: 'Description', sortedName: "description", visible: true },
            { text: 'Inspection Date', sortedName: "inspectionDate", visible: true },
            { text: 'Next Inspection Date', sortedName: "nextInspectionDate", visible: true },
            { text: 'Next Test Date', sortedName: "nextTestDate", visible: true },
            { text: 'Safe For Use', sortedName: "safeForUse", visible: true },
            { text: 'Presented', sortedName: "missing", visible: true },
            { text: 'Serial Number', sortedName: "serialNumber", visible: true },
            { text: 'Asset Number', sortedName: "assetNumber", visible: true },
            { text: 'Defects', sortedName: "defects", visible: true },
            { text: 'Manufacturer', sortedName: "manufacturerName", visible: true },
            { text: 'Category', sortedName: "categoryName", visible: true },
            { text: 'Location', sortedName: "locationName", visible: true },
            { text: 'Company', sortedName: "companyName", visible: true },
          ]}
          isSelectAllChecked={isSelectAllChecked}
          onSelectAll={addAllToBasket}
          onClearAll={removeSelectedPageFromBasket}
          isInspectionsPage={true}
        />

        {inspectionResult.data?.list.map((element: any) => {
          return (
            <DataTable.Row key={element.id}>
              <DataTable.Cell>
                {element.missing == false && <BasketIcon inspectionId={element.id} serialNumber={element.serialNumber} type={"rote"} masterCategoryName={element.masterCategoryName} />}
              </DataTable.Cell>
              <DataTable.Cell>
                <View style={{ flexDirection: 'row' }}>
                  {element.missing == false && <TouchableOpacity onPress={() => downloadCert(element)}><Foundation name="page-pdf" size={24} color="black" /></TouchableOpacity>}
                  {element.missing == false && element.ramsId != null && <TouchableOpacity style={{ marginLeft: 20 }} onPress={() => downloadRAMS(element)}><Foundation name="page-pdf" size={24} color="blue" /></TouchableOpacity>}
                  {element.questions.length > 0 && <TouchableOpacity style={{ marginLeft: 20 }} onPress={() => downloadChecklist(element)}><Foundation name="page-pdf" size={24} color="purple" /></TouchableOpacity>}
                  {element.images != null && element.images.length > 0 &&
                    <TouchableOpacity style={{ marginLeft: 18 }} onPress={() => setImagesToView(element.images)}>
                      <Foundation name="camera" size={24} color="black" />
                    </TouchableOpacity>
                  }
                </View>
              </DataTable.Cell>
              <DataTable.Cell>
                <EquipmentFiles equipmentId={element.equipmentId} />
              </DataTable.Cell>
              <DataTable.Cell>{element.description}</DataTable.Cell>
              <DataTable.Cell><Text>{element.missing ? '-' : element.inspectionDateFormatted}</Text></DataTable.Cell>
              <DataTable.Cell><Text>{element.missing ? '-' : element.nextInspectionDateFormatted}</Text></DataTable.Cell>
              <DataTable.Cell><Text>{element.nextTestDateFormatted}</Text></DataTable.Cell>
              <DataTable.Cell>{element.safeForUseFormatted}</DataTable.Cell>
              <DataTable.Cell>{invertBoolString(element.missingFormatted)}</DataTable.Cell>
              <DataTable.Cell>{element.serialNumber}</DataTable.Cell>
              <DataTable.Cell>{element.assetNumber}</DataTable.Cell>
              <DataTable.Cell>{element.defects}</DataTable.Cell>
              <DataTable.Cell>{element.manufacturerName}</DataTable.Cell>
              <DataTable.Cell>{element.categoryName}</DataTable.Cell>
              <DataTable.Cell>{element.locationName}</DataTable.Cell>
              <DataTable.Cell>{element.companyName}</DataTable.Cell>
            </DataTable.Row>
          )
        })}
      </DataTable>

      <PaginationComponent
        numberOfItemsPerPage={numberOfItemsPerPage}
        setNumberOfItemsPerPage={setNumberOfItemsPerPage}
        page={page}
        setPage={setPage}
        fetchResult={inspectionResult}
        loading={loading}
        setLoading={setLoading}
      />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  }

});
